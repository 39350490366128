<script>
import { ref } from 'vue';
import { detect } from 'detect-browser';
import { useAppStore } from '@/store';

import AudioPlayer from '@/components/AudioPlayer.vue';
import SwiperElement from '@/components/SwiperElement.vue';
import VideoElement from '@/components/VideoElement.vue';
import VinylRecord from '@/components/VinylRecord.vue';

export default {
    name: "DetailObject",

    components: {
        AudioPlayer,
        SwiperElement,
        VideoElement,
        VinylRecord,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        caption: {
            type: String,
            required: false,
            default: '',
        },
    },

    emits: [
        "linkClick",
        "playAudio"
    ],

    data() {
        return {
            browser: { name: 'unknown', version: 0, os: 'unknown' },
        };
    },

    setup() {
        const store = useAppStore();
        const vinylRecord = ref( null );
        const audioPlayer = ref( null );
        const videoElement = ref( null );

        return {
            store,
            audioPlayer,
            videoElement,
            vinylRecord,
        };
    },

    computed: {
        isGraphic() {
            return this.data.Type == 'Graphic';
        },

        isAudioWithGraphic() {
            return this.data.Type == 'Audio' && this.data.Image;
        },

        isSwiper() {
            return this.data.Type == 'Swiper' && this.data.swiper;
        },

        isVideo() {
            return this.data.Type == 'Video';
        },

        hasCaption() {
            if (this.data.Settings && this.data.Settings.useDetailCaption && this.data.Settings.useDetailCaption == true && this.caption != '') {
                return true;
            }
            return this.data.Image && this.data.Image.caption && this.data.Image.caption !== '';
        },

        captionText() {
            if (this.data.Settings && this.data.Settings.useDetailCaption && this.data.Settings.useDetailCaption == true) {
                return this.caption;
            }
            return this.data.Image.caption;
        },

        styleDefinition() {
            const styles = [];

            if ( this.data && this.data.Xpos ) styles.push( `left: ${ this.data.Xpos / 19.20 }%` );
            if ( this.data && this.data.Ypos ) styles.push( `top: ${ this.data.Ypos / 10.80 }%` );

            if ( this.isGraphic || this.isAudioWithGraphic ) {
                styles.push( `width: ${ this.data.Image.width / 19.20 }vw` );
                styles.push( `height: ${ this.data.Image.height / 19.20 }vw` );
            } 

            if ( this.isVideo ) {
                if (this.data.VideoFrame) {
                    styles.push( `width: ${ this.data.VideoFrame.width / 19.20 }vw` );
                    styles.push( `height: ${ this.data.VideoFrame.height / 19.20 }vw` );
                } else {
                    styles.push( `width: ${ this.data.VideoWidth / 19.20 }vw` );
                    styles.push( `height: ${ this.data.VideoHeight / 19.20 }vw` );
                }
            } 

            if ( this.isSwiper ) {
                // get max width and height of images in Layer.Image
                let w = 0, h = 0;
                if ( this.data.swiper.Layers ) {
                    this.data.swiper.Layers.forEach( layer => {
                        if ( layer.Image && layer.Image.width > w ) w = layer.Image.width;
                        if ( layer.Image && layer.Image.height > h ) h = layer.Image.height;
                    });
                }
                styles.push( `width: ${ w / 19.20 }vw` );
                styles.push( `height: ${ h / 19.20 }vw` );
            } 

            const transforms = ['translate(-50%, -50%)'];
            if ( this.data && this.data.Rotation ) transforms.push( `rotate(${ this.data.Rotation }deg)` );
            if ( this.data && this.data.Scale ) transforms.push( `scale(${ this.data.Scale })` );
            styles.push( `transform: ${ transforms.join( ' ' ) }` );

            return styles.join( ';' );
        },

        graphicDimensions() {
            if (!this.data.Image) return { width: 0, height: 0 };
            return { width: this.data.Image.width, height: this.data.Image.height };
        },

        graphicSource() {
            if (!this.data.Image) return "";
            return this.store.strapiUrl + this.data.Image.url;
        },

        graphicSource2() {
            if (!this.data.Image2) return "";
            return this.store.strapiUrl + this.data.Image2.url;
        },

        graphicStyle() {
            if (!this.data.Image) return "";
            
            const styles = [];

            styles.push( `width: ${ this.data.Image.width / 19.20 }vw` );
            styles.push( `height: ${ this.data.Image.height / 19.20 }vw` );

            return styles.join( ';' );
        },

        textStyle() {
            if (!this.data.TextWidth) return "";
            
            const styles = [];

            styles.push( `width: ${ this.data.TextWidth / 19.20 }vw` );

            if ( this.data.Settings && this.data.Settings.fontSize ) styles.push( `font-size: ${ this.data.Settings.fontSize }` );
            if ( this.data.Settings && this.data.Settings.fontFamily ) styles.push( `font-family: ${ this.data.Settings.fontFamily }` );

            return styles.join( ';' );
        },

        hashId() {
            return this.hash( this.data.Title );
        },

        // audioFile() {
        //     return this.store.getAudioFileById( this.selectedObject.Detail.Settings.audioFileId ) || false;
        // },
    },

    methods: {
        hash( s ) {
            if ( !s ) return null;
            return "h" + String( s.split( "" ).reduce( function ( a, b ) {
                a = ( ( a << 5 ) - a ) + b.charCodeAt( 0 );
                return a & a;
            }, 0 ) );
        },

        pause() {
            if ( this.data.Type == 'RecordPlayer' || this.data.Type == 'Audio' ) {
                this.pausePlay();
            }
            if ( this.data.Type == 'Video' && this.videoElement && this.videoElement.pauseVideo )
            {
                this.videoElement.pauseVideo();
            }
        },

        resume() {
            if ( this.data.Type == 'RecordPlayer' || this.data.Type == 'Audio' ) {
                this.resumePlay();
            }
            if ( this.data.Type == 'Video' && this.videoElement && this.videoElement.resumeVideo )
            {
                this.videoElement.resumeVideo();
            }
        },

        postEnter() {
            if ( this.data.Type == 'Video' && this.videoElement && this.videoElement.postEnter ) {
                this.videoElement.postEnter();
            }
            if ( this.data.Type == 'Audio' && this.audioPlayer && this.audioPlayer.postEnter ) {
                this.audioPlayer.postEnter();
            }
        },

        exit() {
            if ( this.data.Type == 'RecordPlayer' || this.data.Type == 'Audio' ) {
                this.stopPlay();
            }
            if ( this.data.Type == 'Video' && this.videoElement && this.videoElement.exit ) {
                this.videoElement.exit();
            }
        },

        pausePlay() {
            if ( this.data.Type == 'RecordPlayer' ) {
                this.vinylRecord.toggleRecord( false, true );
            }
            if ( this.data.Type == 'Audio' ) {
                this.audioPlayer.togglePlayer( false, true );
            }
            if ( this.data.Type == 'Video' && this.videoElement && this.videoElement.pauseVideo )
            {
                this.videoElement.pauseVideo();
            }
        },

        resumePlay() {

        },

        stopPlay(suppressEmit = false) {
            // console.log(`stopPlay ${this.data.Title}`, suppressEmit);
            if ( this.data.Type == 'RecordPlayer' ) {
                this.vinylRecord.toggleRecord( false, suppressEmit );
            }
            if ( this.data.Type == 'Audio' ) {
                this.audioPlayer.togglePlayer( false, suppressEmit );
            }
        },

        onPlayAudio( dataId ) {
            if ( this.data.Type == 'RecordPlayer' || this.data.Type == 'Audio' ) {
                this.$emit( 'playAudio', dataId );
            }
        },

        linkHandler() {
            if (!this.data.LinkTargetId) return;
            this.$emit( 'linkClick', this.data.LinkTargetId );
        }
    },

    mounted() {
        this.browser = detect() || { name: 'unknown', version: 0, os: 'unknown' };
        // console.log(this.data.Title);
    },

};
</script>

<template>
    <div class="detail-object" :style="styleDefinition">
        
        <!-- Text -->
        <div v-if="data.Type == 'Text' && data.Text && data.Text !== ''" class="text-txt" :style="textStyle">
            {{ data.Text }}
            <span v-if="hasCaption" class="bron">{{ captionText }}</span>
        </div>
        
        <!-- Graphic -->
        <div v-if="data.Type == 'Graphic' && data.Text && data.Text !== ''" class="graphic-txt" >
            {{ data.Text }}
            <span v-if="hasCaption" class="bron">{{ captionText }}</span>
        </div>
        <img v-if="data.Type == 'Graphic'" :src="graphicSource" :style="graphicStyle" >
        
        <!-- Frame -->
        <div v-if="data.Type == 'Frame'" class="frame-bg" ></div>
        <div v-if="data.Type == 'Frame'" class="frame-txt" >
            {{ data.Text }}
            <span v-if="hasCaption" class="bron">{{ captionText }}</span>
        </div>
        <img v-if="data.Type == 'Frame'" class="frame-img" :src="graphicSource" :style="graphicStyle" >
        
        <!-- Link -->
        <div v-if="data.Type == 'Link' && data.Text && data.Text !== ''" class="graphic-txt" @click="linkHandler" >
            {{ data.Text }}
            <span v-if="hasCaption" class="bron">{{ captionText }}</span>
        </div>
        <img v-if="data.Type == 'Link'" :src="graphicSource" :style="graphicStyle" @click="linkHandler">
        
        <!-- Swiper -->
        <SwiperElement v-if="data.Type == 'Swiper' && data.swiper" 
            ref="swiper" 
            :data="data.swiper" 
            />

        <!-- Audio -->
        <AudioPlayer v-if="data.Type == 'Audio'" 
            ref="audioPlayer" 
            :data="data" 
            :audioFile="data.Audiofile" 
            :graphicDimensions="graphicDimensions"
            :graphicSource="graphicSource" 
            :graphicSource2="graphicSource2" 
            :style="graphicStyle" 
            @play-audio="onPlayAudio" 
            />

        <!-- Video -->
        <VideoElement v-if="data.Type == 'Video'" 
            ref="videoElement" 
            :data="data" 
            />
        
        <!-- RecordPlayer -->
        <VinylRecord v-if="data.Type == 'RecordPlayer'" 
            ref="vinylRecord" 
            :dataId="`do-${data.id}`" 
            :audioFile="data.Audiofile" 
            @play-audio="onPlayAudio" 
            />

    </div>
</template>