<script>
import { ref } from 'vue';
import { useAppStore } from '@/store';

export default {
    name: 'SwiperElement',

    components: {
    },

    props: {
        data: {
            type: Object,
            required: false,
        },
    },

    data() {
        return {
            current: 0,
        };
    },

    setup() {
        const store = useAppStore();
        const rootEl = ref( null );

        return {
            rootEl,
            store,
        };
    },

    computed: {
        swiperClass() {
            const cls = [ 'swiper-object' ];
            return cls.join( ' ' );
        },

        currentLayer() {
            return this.data.Layers.filter( ( layer, index ) => index == this.current );
        },

        hasLayers() {
            return this.data && this.data.Layers && this.data.Layers.length > 0;
        },

        hasMultipleLayers() {
            return this.hasLayers && this.data.Layers.length > 1;
        }
    },

    methods: {
        hasImage( layer ) {
            return layer && layer.Image && layer.Image.url;
        },

        imageUrl( layer ) {
            return this.store.strapiUrl + layer.Image.url;
        },

        nextSlide() {
            if ( this.current < this.data.Layers.length - 1 )
            {
                this.current++;
            } else {
                this.current = 0;
            }
        },

        prevSlide() {
            if ( this.current > 0 )
            {
                this.current--;
            } else {
                this.current = this.data.Layers.length - 1;
            }
        },

        exit() {
            console.log( 'SwiperElement::exit' );
        },
    },

    mounted() {
        if ( this.data && this.data.Settings )
        {
            console.log( 'SwiperElement::Settings', this.data.Settings );
        }
    }
};
</script>

<template>
    <div v-if="hasLayers" ref="rootEl" class="swiper-container">
        <TransitionGroup name="swiper-slide">
        <div v-for="layer, index in currentLayer" :key="index" class="swiper-slide">
            <img v-if="hasImage( layer )" :src="imageUrl( layer )">
            <div class="swiper-slide-content">
                <h3>{{ layer.Title }}</h3>
                <p v-if="layer.BodyContent">{{ layer.BodyContent }}</p>
            </div>
        </div>
        </TransitionGroup>
        <span v-if="hasMultipleLayers" @click="prevSlide()" 
            class="control control-back">&lsaquo;
        </span>
        <span v-if="hasMultipleLayers" @click="nextSlide()" 
            class="control control-forward">&rsaquo;
        </span>
    </div>
</template>
