<script>
// import { detect } from 'detect-browser';
import { useAppStore } from '@/store';

export default {
    name: 'VideoEventPlayer',

    props: {
        events: {
            type: Object,
            // required: true,
            default: () => { },
        },

        videoPlayer: {
            type: Object,
            // required: true,
            default: () => { },
        },
    },

    data() {
        return {
            // el: null,
            eventsFired: [],
            tickInterval: null,
        };
    },

    setup() {
        const store = useAppStore();
        return { store };
    },

    mounted() {
        // this.el = document.getElementById( 'subtitles' );

        this.tickInterval = setInterval( this.tick, 100 );

    },

    // computed: {
    // },

    methods: {
        tick() {
            if ( !this.videoPlayer.el || !this.videoPlayer.getIsPlaying() ) return;

            const videoTime = this.videoPlayer.el.currentTime;

            const evt = this.events && this.events.find( ( x ) => {
                return videoTime >= x.time;
            } );

            // Fire event if found and not already fired
            if ( evt && !this.eventsFired.includes( evt ) )
            {
                this.eventsFired.push( evt );
                // console.log( 'Video event', evt.name, evt.data );
            }
        },

        reset() {
            clearInterval( this.tickInterval );

            this.events.forEach( ( x ) => x.fired = false );
        },
    },
};
</script>

<template>
    <div></div>
</template>