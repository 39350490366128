const getMaterialHTML = ( id, materials, strapiUrl ) => {
    const material = materials.find( ( mat ) => mat.id === id );
    const mime = material.File.mime;
    const isZip = mime.includes( 'zip' );
    const isPdf = mime.includes( 'pdf' );
    const isImage = mime.includes( 'image' );
    const isVideo = mime.includes( 'video' );
    const isAudio = mime.includes( 'audio' );
    const icon =
            isZip   ? 'folder_zip' :
            isPdf   ? 'draft' :
            isImage ? 'image' :
            isVideo ? 'movie' :
            isAudio ? 'music_note' :
                    'unknown';
    return `<a class="material-item" target="_blank" href="${ strapiUrl }${ material.File.url }">
            <aside><span class="material-symbols-outlined">${ icon }</span></aside>
            <article>
              <h3>${ material.Title }</h3>
              <p>${ ( material.Description ) ?? '' }</p>
            </article></a>`;
};

const getInfoPageHTML = ( id, infoPages, strapiUrl ) => {
    const infoPage = infoPages.find( ( page ) => page.id === id );
    const thumbStyle = infoPage.Image ? `background-image: url(${ strapiUrl }${ infoPage.Image.url })` : '';
    return `<a class="infopage-item" href="${ window.location.origin }/info/${ infoPage.Slug }">
            <aside style="${ thumbStyle }"></aside>
            <article>
              <h3>${ infoPage.Title }</h3>
              <p>${ infoPage.Excerpt }</p>
            </article></a>`;
};

const populateMaterials = ( content, materials, strapiUrl ) => {
    if ( content.includes( '%MATERIALS%' ) ) {
        const materialList = materials.map( ( mat ) => getMaterialHTML(mat.id, materials, strapiUrl) ).join( '' );
        content = content.replace( /%MATERIALS%/g, `<div class="materials">${ materialList }</div>` );
    }
    content = content.replace( /%MATERIALS:([\d a-z]+(,[ a-z\d]+)*)%/g, ( match, ids ) => {
        const idList = ids.toLowerCase().split( "," );
        const contents = idList.map( ( id ) => {
            if ( isNaN( parseInt( id ) ) ) return "";
            return getMaterialHTML( parseInt( id ), materials, strapiUrl );
        } );
        return `<div class="materials">` + contents.join( "" ) + `</div>`;
    } );
    return content;
};

const populateInfoPages = ( content, infoPages, strapiUrl ) => {
    if ( content.includes( '%INFOPAGES%' ) ) {
        const infoPagesList = infoPages.map( ( infoPage ) => getInfoPageHTML(infoPage.id, infoPages, strapiUrl ) ).join( '' );
        content = content.replace( '%INFOPAGES%', `<div class="infopages">${ infoPagesList }</div>` );
    }
    content = content.replace( /%INFOPAGES:([\d a-z]+(,[ a-z\d]+)*)%/g, ( match, ids ) => {
        const idList = ids.toLowerCase().split( "," );
        const contents = idList.map( ( id ) => {
            if ( isNaN( parseInt( id ) ) ) return "";
            return getInfoPageHTML( parseInt( id ), infoPages, strapiUrl );
        } );
        return `<div class="infopages">` + contents.join( "" ) + `</div>`;
    } );
    return content;
};

export function parseContent( content, store ) {

    if ( content.includes( '%MATERIALS' ) ) {
        content = populateMaterials( content, store.app_content.materials, store.strapiUrl );
    }

    if ( content.includes( '%INFOPAGES' ) && "undefined" !== typeof this.store.app_content.infoPages ) {
        content = populateInfoPages( content, store.app_content.infoPages, store.strapiUrl );
    }

    content = content.replace( /"\/uploads\//g, '"' + store.strapiUrl + '/uploads/' );

    return content;
}