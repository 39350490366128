<script>
import { useAppStore } from '@/store';

export default {
    name: "PageFooter",

    setup() {
        const store = useAppStore();
        return { 
            store, 
        };
    },

    computed: {
    },

    methods: {
    },

    mounted() {
    },

};
</script>

<template>
    <br class="clear" />
    <footer data-nosnippet>
        Loop direct <router-link to="/start">de straat op</router-link> of ga naar de <router-link to="/">home</router-link>
    </footer>
</template>