<script>
export default {
    name: 'ChapterTitle',
    
    props: {
        title: {
            type: String,
            default: ''
        },
        titleStyle: {
            type: String,
            default: ''
        },
        period: {
            type: String,
            default: ''
        },
        periodStyle: {
            type: String,
            default: ''
        },
        periodInfo: {
            type: String,
        },
    },    

    computed: {
        periodClasses() {
            const classes = ['period'];

            if (this.periodStyle === 'period--dark') {
                classes.push('period--dark');
            }
            
            return classes.join(' ');
        },

        hasPeriodInfo() {
            return this.periodInfo && this.periodInfo !== '';
        }
    },
}
</script>

<template>
    <div ref="title" class="chapterTitle">
        <h1 :style="titleStyle">{{ title }}</h1>
        <p v-if="!hasPeriodInfo" :class="periodClasses" :style="periodStyle">{{ period }}</p>
        <p v-if="hasPeriodInfo" :class="periodClasses" :style="periodStyle">
            <input id="chTitPer" type="checkbox">
            <label @click="$gtag.event( 'click', { target: 'PeriodText', context: 'DeJoodseStraatView' } )" for="chTitPer">
                <span>{{ period }}</span>
                <div class="info">{{ periodInfo }}</div>
            </label>
        </p>
    </div>
</template>