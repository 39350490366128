<script>
import WedgeObject from './WedgeObject.vue';

export default {
    name: 'WedgesPlate',

    components: {
        WedgeObject,
    },
    
    props: {
        chapter: {
            type: Object,
        },
    },    

    computed: {
        wedges() {
            if (!this.chapter || !this.chapter.Settings || !this.chapter.Settings.wedges) return [];
            return this.chapter.Settings.wedges;
        },
    },

    methods: {
        wedgeKey( index ) {
            return index;
            // let c = this.chapter.Number ? this.chapter.Number : 1;
            // return c * 10 + index;
        },

        growDark() {
            const wedge1 = this.$el.querySelector('.wedge');

            const style = window.getComputedStyle( wedge1 );
            const matrix = style.transform || style.webkitTransform || style.mozTransform;
            const matrixValues = matrix.match( /matrix.*\((.+)\)/ )[ 1 ].split( ', ' );
            const x = matrixValues[ 4 ];
            const y = matrixValues[ 5 ];
            wedge1.style.transition = 'transform 5s ease-in-out';
            wedge1.style.transform = `translate(${x}px, ${y}px) scale(2.4)`;
        }
    },
}
</script>

<template>
    <div v-if="chapter" class="wedges">
        <WedgeObject v-for="wedge, index in wedges" :key="wedgeKey(index)" :data="wedge"></WedgeObject>
    </div>
</template>