<script>
import { ref } from 'vue';
import { useAppStore, EXP_STATES } from '@/store';

import ProgressBarChapter from './ProgressBarChapter.vue';

export default {
    name: "ProgressBar",

    components: {
        ProgressBarChapter,
    },

    props: {
        activeChapterNumber: {
            type: Number,
            required: true,
            default: 1,
        },
    },

    // data() {
    //     return {
    //         initialized: false,
    //     };
    // },

    setup() {
        const store = useAppStore();

        const barChapterList = ref( null );

        return { 
            barChapterList,
            store,
        };
    },

    computed: {
        // app_content() {
        //     return this.store.app_content;
        // },

        chapters() { return this.store.hasContent ? this.store.app_content.chapters : []; },
    },

    methods: {
        chapterClickHandler( chapterNum ) {
            // console.log( "ProgressBar::chapterClickHandler", chapterNum || "-" );

            // Only when in SCENE or DETAIL state
            if ( ![EXP_STATES.SCENE, EXP_STATES.DETAIL].includes( this.store.getExperienceState() )) {
                console.log('ProgressBar::chapterClickHandler - not in SCENE or DETAIL state');
                return;
            }

            // const chapterEl = document.getElementById( `chapter-${ this.activeChapterNumber }` );
            // if (chapterEl) {
            //     chapterEl.style.width = "45px";
            // }
            
            this.$emit( "clickChapter", chapterNum );
        },

        updateCurrentBarWidth() {
            // console.log( "ProgressBar::updateCurrentBarWidth" );
            this.barChapterList.forEach( (barChapter) => {
                if (barChapter.active) {
                    barChapter.setBarWidth();
                } else {
                    barChapter.resetBarWidth();
                }
            });
        }
    },

    mounted() {
        // console.log('ProgressBar::mounted', this.activeChapterNumber);
        // let i1 = setInterval(() => {
        //     if ( this.store.hasContent ) {
        //         clearInterval(i1);
        //     }
        // }, 100);
    },

    // watch: {
    //     app_content( val ) {
    //         console.log("--> app_content", val );
    //     }
    // }
};

</script>

<template>
    <nav v-if=" store.hasContent " class="progress-bar">
        <ProgressBarChapter ref="barChapterList" v-for=" chapter  in chapters" :key=" chapter.id " :chapter=" chapter "
            :active=" chapter.Number == activeChapterNumber " @chapterClick=" chapterClickHandler " />
    </nav>
</template>