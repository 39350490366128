<script>
import { ref } from 'vue';
import { useAppStore } from '@/store';

// import anime from 'animejs/lib/anime.es.js';

import IntroPlayer from '@/components/IntroPlayer.vue';
import SubtitlePlayer from '@/components/SubtitlePlayer.vue';

export default {
  name: "LandingAnimView",

  components: {
    IntroPlayer,
    SubtitlePlayer,
  },

  setup() {
    const store = useAppStore();
    const introPlayer = ref( null );
    const subtitles = ref( null );

    return {
      introPlayer,
      subtitles,
      store,
    };
  },

  computed: {
    content() { return this.store && this.store.hasContent ? this.store.app_content.pages.home : ''; },
    hasSubtitles() { return this.store && this.store.hasContent && this.store.app_content.pages && this.store.app_content.pages.home && this.store.app_content.pages.home.Settings && this.store.app_content.pages.home.Settings.landingVideoSubtitles; },
    subtitlesList() { return this.hasSubtitles ? this.content.Settings.landingVideoSubtitles.map(sub => {
      return {
        start_time: sub.start_time,
        end_time: sub.end_time,
        text: sub.text,
        position: 'bottom'
      };
    
    }) : []; },

    subtitleStyle() {
      const styles = [];
      if ( this.hasSubtitles && this.store.app_content.pages.home.Settings.subtitleColor )
      {
        styles.push( `color: ${ this.store.app_content.pages.home.Settings.subtitleColor }` );
      }
      return styles.join( ';' );
    },
  },

  methods: {

    pauseLanding() {
      // console.log( 'LandingView::pauseLanding' );
      if ( this.introPlayer ) 
      {
        this.introPlayer.pause();
      }
    },
    
    resumeLanding() {
      // console.log( 'LandingView::resumeLanding' );
      if ( this.introPlayer )
      {
        this.introPlayer.resume();
      }
    },

    videoEnded() {
      console.log( 'LandingView::videoEnded' );
      this.subtitles.reset();
      this.introPlayer.fadeOut();
    },
  },

  mounted() {

    // setTimeout(() => {
      
    //   anime( {
    //     targets: document.querySelectorAll( '.landing-backdrop > .object' ),
    //     // targets: document.querySelectorAll( '.kl' ),
    //     rotateX: '0deg',
    //     // scaleY: '1',
    //     // translateZ: `${i * 40}px`,
    //     duration: 2300,
    //     // easing: `easeOutElastic(1, 0.3)`,
    //     // delay: anime.stagger( 100 ),
    //     init() {
    //       // console.log( 'init' );
    //     },
    //     complete: () => console.log('done')
    //   } );

    // }, 500);


    // wait for intro player
    let int1 = setInterval( () => {
      // console.log('-');
      if ( this.introPlayer ) {
        clearInterval( int1 );
        this.introPlayer.fadeIn();
        this.introPlayer.play();

        this.subtitles.start();
      }
    // }, 50);
    }, 3500);

    this.$gtag.event( 'view', { name: 'LandingView' } );
  },
};
</script>

<template>
  <div class="router-view" id="home" v-if="store.hasContent">

    <div class="landing-backdrop">
      <img src="@/assets/backdrop.webp" id="bd" class="object" />
      <img src="@/assets/wedge-yellow-center.svg" id="w3" class="wedge" />
      <img src="@/assets/b1.webp" id="b1" class="object" />
      <img src="@/assets/b2.webp" id="b2" class="object" />
      <img src="@/assets/herenhuis.webp" id="hh" class="object" />
      <img src="@/assets/girl.webp" id="gr" class="object" />
      <img src="@/assets/overlay.svg" id="ov" class="overlay" />
      <img src="@/assets/tree.webp" id="tr" class="object2" />
      
    </div>
    
    <div class="landing-wedges">
      <img src="@/assets/wedge-orange-bottom.svg" id="w5" class="wedge" />
      <img src="@/assets/wedge-blue-left.svg" id="w1" class="wedge" />
      <img src="@/assets/wedge-blue-top.svg" id="w2" class="wedge" />
      <img src="@/assets/wedge-yellow-right.svg" id="w4" class="wedge" />
    </div>
    
    <section class="content">
      <h1 class="page-title">{{ content.Title }}</h1>
      <section v-html="content.Body"></section>
    </section>

    <IntroPlayer v-if="store.hasContent" ref="introPlayer" @ended="videoEnded"></IntroPlayer>

    <SubtitlePlayer v-if="hasSubtitles" :videoPlayer="introPlayer || {}" :subtitles="subtitlesList"
      ref="subtitles" id="subtitleEl" :style="subtitleStyle">
    </SubtitlePlayer>

    <nav>
      <router-link to="/start" @click="$gtag.event( 'click', { target: 'NaarDeStraatButton', context: 'LandingView' } )" class="button">{{ content.Settings.startButtonText || "Start" }}</router-link>
    </nav>

  </div>
</template>