<script>
import { useAppStore } from '@/store';
// import { storeToRefs } from 'pinia';

import PageFooter from '@/components/PageFooter.vue';

export default {
  name: "OverView",

  components: {
    PageFooter,
  },

  setup() {
    const store = useAppStore();

    return {
      store,
    };
  },

  computed: {
    hasContent() { return null !== this.store.app_content; },
    content() { return this.hasContent ? this.store.app_content.pages.over : ''; },
  },

  methods: {
    parseImageUrls(content) {
      if (!content) return '';
      return content.replace( /src="\/uploads/g, `src="${ this.store.strapiUrl }/uploads` );
    }
  },

  mounted() {
    this.$gtag.event( 'view', { name: 'OverView' } );
  },
};
</script>
<template>
  <div id="about" class="router-view" v-if=" store.app_content ">
    <!--<img class="logo" src="../assets/testimage.jpeg" alt="logo" />-->
    <h1 class="page-title">{{ content.Title }}</h1>
    <p v-html="parseImageUrls(content.Body)"></p>
    <PageFooter />
  </div>
</template>