<script>
import "@/scss/base.scss";
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useAppStore } from "@/store";
import NavComponent from "@/components/NavComponent.vue";

export default defineComponent( {
  name: "App",

  components: {
    NavComponent,
  },

  setup() {
    const store = useAppStore();
    const route = useRoute();

    const routerview = ref( null );

    // window.djsEvents = createApp();
    // window.djsEvents.$on( 'my-event', ( data ) => {
    //   console.log( "App|my-event", data );
    // } );

    watch( () => route.name, ( newValue, oldValue ) => {

      // console.log( "App|watch|route.name", oldValue, "-->", newValue );

      document.body.classList.remove( ...document.body.classList );
      document.body.classList.add( ...route.meta.class.split( ' ' ) );
      
      if ( window.DJS_IS_DEBUG )
      {
        document.body.classList.add( 'is-debug' );
      }

      if ( newValue !== oldValue )
      {
        // cleanup on change of page
        // document.body.classList.remove( 'intro-playing' );
        // const stage = document.getElementById( 'stage' )
        // !stage || stage.classList.remove( 'active' );

        // Handling on /start
        if ( route.path === '/start' )
        {
          if (store.activeChapterNumber !== 1) {
            store.setActiveChapterNumber( window.START_CHAPTER );
          }
          // document.getElementById('title-wrapper').classList.add( 'show');
        }
        // Handling on non- /start 
        else 
        {
          // document.getElementById('title-wrapper').classList.remove( 'show');
        }
      }
    } );

    return {
      store,
      route,
      routerview
    };
  },

  computed: {
    activeChapterNumber() { return this.store.activeChapterNumber; },
    chapter() { return this.store.hasContent ? this.store.getChapterByNumber( this.activeChapterNumber ) : null; },
    chapters() { return this.store.hasContent ? this.store.app_content.chapters : []; },
    chapterBackground() { return this.store.hasContent ? this.store.strapiUrl + this.store.app_content.settings.ChapterBackground.url : ''; },
  },

  methods: {

    clickChapterHandler( chapterNum ) {
      // console.log( "App::clickChapterHandler", chapterNum, typeof chapterNum );
      this.store.setActiveChapterNumber( chapterNum );
      setTimeout( () => {
        this.routerview.$.ctx.startChapter();
      }, 500 );
    },

    menuActionHandler( action ) {
      // console.log( "App|menuActionHandler", action, this.route.path );

      // Handles on /start
      if ( this.route.path === '/' )
      {
        if ( action == 'open' )
        {
          this.routerview.$.ctx.pauseLanding();
        }
        else
        {
          this.routerview.$.ctx.resumeLanding();
        }
      }

      if ( this.route.path === '/start' )
      {
        if ( action == 'open' )
        {
          this.routerview.$.ctx.pauseChapter();
        } 
        else
        {
          this.routerview.$.ctx.resumeChapter();
        }
      }
    },
  },

  created() {
  },

  mounted() {
    const labelEl = document.createElement( 'label' );
    labelEl.htmlFor = 'cb-lores-warn';
    labelEl.classList.add('lores-icon');
    const inputEl = document.createElement( 'input' );
    inputEl.type = 'checkbox';
    inputEl.classList.add('cb-lores-warn');
    inputEl.id = 'cb-lores-warn';
    const divEl = document.createElement( 'div' );
    divEl.setAttribute('data-nosnippet', '')
    divEl.classList.add('lores-modal');
    divEl.innerHTML = `
        <div class="lores-modal-title">Afwijkend scherm</div>
        <div class="lores-modal-body">
          <p>Dit scherm is momenteel n&eacute;t een beetje klein, afwijkend of gedraaid. Je kunt alles bekijken, maar mogelijk valt er iets buiten beeld, of kun je het niet aanraken.</p>
          <p>Deze app is gemaakt om te bekijken op een iPad in liggende modus, laptop, Chromebook of groot scherm.</p>
        </div>
        <div id="lores-close" class="lores-modal-close">X</div> 
    `;

    document.body.appendChild(labelEl);
    document.body.appendChild(inputEl);
    document.body.appendChild(divEl);

    document.getElementById('lores-close').addEventListener('click', () => {
      document.getElementById('cb-lores-warn').checked = false;
    });
  },

} );

</script>

<template>

  <router-view @click-chapter=" clickChapterHandler " v-slot=" { Component } ">
    <component ref="routerview" :is=" Component " />
  </router-view>

  <NavComponent @clickChapter="clickChapterHandler" @menuAction="menuActionHandler" />

  <div id="app-dbg" class="debug" data-nosnippet>
    State: {{ store.experienceState }}
    <div id="app-dbg-body" class="body">&nbsp;</div>
  </div>
</template>
