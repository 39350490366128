<script>
import { useAppStore } from '@/store';
// import { storeToRefs } from 'pinia';
import { parseContent } from '@/util/contentparser';

// import VueMarkdown from 'vue-markdown';
import PageFooter from '@/components/PageFooter.vue';

export default {
  name: "ExtrasView",

  components: {
    // VueMarkdown,
    PageFooter,
  },

  setup() {
    const store = useAppStore();

    return {
      store,
      parseContent,
    };
  },

  // methods: {

  // },

  computed: {
    hasContent() { return null !== this.store.app_content; },
    content() { return this.hasContent ? this.store.app_content.pages.extra : ''; },
  },

  mounted() {
    this.$gtag.event( 'view', { name: 'ExtrasView' } );

  },
};
</script>

<template>
  <div id="about" class="router-view" v-if="this.store.app_content">
    <!--<img class="logo" src="../assets/testimage.jpeg" alt="logo" />-->
    <h1 class="page-title">{{ content.Title }}</h1>
    <section v-html="parseContent(content.Body, store)"></section>
    <PageFooter />
  </div>
</template>
