<script>
export default {
    name: "ProgressBarChapterObject",

    props: {
        identifier: {
            type: String,
            required: true,
        },
        hasVisited: {
            type: Boolean,
            required: true,
            default: false,
        },
    },

    computed: {
        chapterObjectClass() {
            return `chapter-item ${ this.hasVisited ? 'visited' : ''}`;
        },
    },

};
</script>

<template>
    <div :class="chapterObjectClass" :data-identifier="identifier"></div>
</template>