<script>
import { useAppStore } from '@/store';
import PageFooter from '@/components/PageFooter.vue';
export default {
  name: "ErrorView",

  components: {
    PageFooter,
  },

  setup() {
    const store = useAppStore();

    return {
      store,
    };
  },

  computed: {
    hasContent() { return null !== this.store.app_content; },
    content() { return this.hasContent ? this.store.app_content.pages.extra : ''; },
  },

  mounted() {
  },
};
</script>
<template>
  <div id="404" class="router-view">
    <h1 class="page-title">Uh oh..</h1>
    <section>
      De opgevraagde pagina bestaat (nog?) niet.
    </section>
    <PageFooter />
  </div>
</template>