<script>
import { ref } from 'vue';
import { useAppStore } from '@/store';

let updateInt, vinylWidth;

export default {
    name: 'VinylRecord',

    props: {
        audioFile: {
            type: Object,
            required: true,
        },
        dataId: {
            type: String,
            required: true,
        }
    },

    emits: [
        "playAudio"
    ],

    setup() {
        const store = useAppStore();
        const recordPlayer = ref( null );
        const progressBar = ref( null );

        return {
            progressBar,
            recordPlayer,
            store,
        };
    },

    methods: {
        toggleRecord(eventOrFlag, suppressEmit = false) {
            if (eventOrFlag === true || (eventOrFlag !== false && this.recordPlayer && !this.recordPlayer.classList.contains('spin'))) {
                if (!suppressEmit) this.$emit('playAudio', this.dataId);

                // spin
                this.recordPlayer.classList.add('spin');
                
                // create audio 
                if (!window.djs_audio_tracks[this.dataId]) {
                    window.djs_audio_tracks[ this.dataId ] = new Audio();
                }

                // play audio
                window.djs_audio_tracks[ this.dataId ].src = `${this.store.strapiUrl}${this.audioFile[0].url}`;
                window.djs_audio_tracks[ this.dataId ].play();
                window.djs_audio_tracks[ this.dataId ].addEventListener('ended', this.audioEnded);
                
                vinylWidth = document.querySelector('.record-progress').offsetWidth;
                updateInt = setInterval(() => {
                    // const recordProgress = document.querySelector('.record-progress-bar');
                    if (this.progressBar) {
                        const progress = window.djs_audio_tracks[ this.dataId ].currentTime / window.djs_audio_tracks[ this.dataId ].duration;
                        this.progressBar.style.width = `${progress * vinylWidth}px`;
                    }
                }, 250);

            } else if (eventOrFlag === false || (eventOrFlag !== true && this.recordPlayer && this.recordPlayer.classList.contains('spin'))) {
                clearInterval(updateInt);
                this.recordPlayer.classList.remove('spin');
                if ( window.djs_audio_tracks[ this.dataId ] ) {
                    window.djs_audio_tracks[ this.dataId ].removeEventListener('ended', this.audioEnded);
                    window.djs_audio_tracks[ this.dataId ].pause();
                }
            }
        },

        audioEnded(e) {
            console.log('audioEnded', e);
            this.toggleRecord(false);
        },

        exit() {
            console.log('VinylRecord::exit');

            clearInterval(updateInt);

            // const recordPlayer = document.getElementById('recordPlayer');
            if (this.recordPlayer && this.recordPlayer.classList.contains('spin')) {
                this.recordPlayer.classList.remove('spin');
            }
            if (window.djs_audio_tracks[ this.dataId ]) window.djs_audio_tracks[ this.dataId ].pause();
        },

        clickProgress(e) {
            // determine where user clicked on progress bar
            // const recordProgress = document.querySelector('.record-progress-bar');
            vinylWidth = document.querySelector('.record-progress').offsetWidth;
            if (this.progressBar) {
                const progress = e.offsetX / vinylWidth;
                const pos = Math.max(0, Math.min(100, (progress||0) * (vinylWidth||1)));
                if (window.djs_audio_tracks[ this.dataId ]) {
                    window.djs_audio_tracks[ this.dataId ].currentTime = ( window.djs_audio_tracks[ this.dataId ].duration||0) * progress;
                }
                this.progressBar.style.width = `${pos}px`;
                this.toggleRecord(true);
            }
        }
    },

    // mounted() {
    //     console.log(this.audioFile[ 0 ] )
    // }
};
</script>

<template>
    <div ref="recordPlayer" id="recordPlayer" class="record-player">
        <div class="record" :onclick="toggleRecord"></div>
        <div class="record-progress" :onclick="clickProgress">
            <div ref="progressBar" class="record-progress-bar"></div>
        </div>
        <!--<div class="record-title">{{ audioFile[ 0 ].Title }}</div>-->
    </div>
</template>