<script>
import { useAppStore } from '@/store';
export default {
    name: "StageObjectLayer",

    props: {
        data: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            stylesheet: null,
        };
    },

    computed: {
        hashId() {
            return this.hash( this.data.Title );
        },
        
        cssTransformValue() {
            // return `translate(` + 
            //     `${ - 50 + ( ( this.data.OffsetX || 0 ) / this.data.Image.width * 100 ) }%,` + 
            //     `${ ( ( this.data.OffsetY || 0 ) / this.data.Image.height * 100 ) }%` + 
            //     `) translateZ(${this.index * 2}px)`;
            const transforms = [
                `translate(` + 
                `${ - 50 + ( ( this.data.OffsetX || 0 ) / this.data.Image.width * 100 ) }%,` + 
                `${ ( ( this.data.OffsetY || 0 ) / this.data.Image.height * 100 ) }%` + 
                `)`
            ];

            if ( this.data.Settings && this.data.Settings.scale && parseFloat( this.data.Settings.scale ) > 0 ) {
                transforms.push( `scale(${ this.data.Settings.scale })` );
            }

            if ( this.data.Settings && this.data.Settings.rotate ) {
                transforms.push( `rotate(${ this.data.Settings.rotate })` );
            }
            
            return transforms.join( " " );
        },

        styleValue() {
            const values = [];
            if (this.data && "undefined" !== this.data.Image) {
                values.push( `background-image: url(${ this.store.strapiUrl }${ this.data.Image.url })` );
                values.push( `width: ${ this.data.Image.width / 19.20 }%` );
                values.push( `height: ${ this.data.Image.height / 10.80 }%` );
            }
            if ( this.data.Settings && "undefined" !== this.data.Settings['mix-blend-mode'] )
            {
                values.push( `mix-blend-mode: ${ this.data.Settings['mix-blend-mode'] }` );                
            }
            if ( this.data.Settings && "undefined" !== this.data.Settings['transform-origin'] )
            {
                values.push( `transform-origin: ${ this.data.Settings['transform-origin'] }` );                
            }
            if ( this.data.Settings && "undefined" !== this.data.Settings['backface-visibility'] )
            {
                values.push( `backface-visibility: ${ this.data.Settings['backface-visibility'] }` );                
            }
            values.push( `transform: ${ this.cssTransformValue }` );
            return values.join( "; " );
            // return `
            //     background-image: url(${ this.store.strapiUrl }${ this.data.Image.url });
            //     width: ${ this.data.Image.width / 19.20 }%;
            //     height: ${ this.data.Image.height / 10.80 }%;
            //     transform: ${ this.cssTransformValue }
            // `;
        }
    },

    methods: {
        hash( s ) {
            if (!s) return null;
            return "h" + String(s.split( "" ).reduce( function ( a, b ) {
                a = ( ( a << 5 ) - a ) + b.charCodeAt( 0 );
                return a & a;
            }, 0 ));
        },
        addStylesheet() {
            this.stylesheet = document.getElementById( `style_${ this.hashId }` );
            if ( !this.stylesheet )
            {
                // this.stylesheet = document.createElement( 'style' );

                this.stylesheet = document.createElement( "link" );
                this.stylesheet.rel = "stylesheet";
                this.stylesheet.id = `style_${ this.hashId }`;
                document.head.appendChild( this.stylesheet );
            }
        },
        addKeyFrames( name, frames ) {
            this.addStylesheet();
            if (!this.stylesheet ) return;
            const css_text = encodeURIComponent(`@keyframes ${name} { ${frames} }`);
            this.stylesheet.href = `data:text/css,${ css_text }`;
        }
    },

    setup() {
        const store = useAppStore();
        return { store };
    },

    mounted() {
        const layerEl = document.getElementById( `layer-${this.hashId}` );
        let animTime;

        if (this.data.Settings && this.data.Settings.idleAnimation) {
            switch( this.data.Settings.idleAnimation ) {

                case 'sway':
                    this.addKeyFrames(
                        `sway_${ this.hashId }`,
                        `0% {transform: ${this.cssTransformValue} rotatez(3deg)} ` + 
                        `100% {transform: ${ this.cssTransformValue } rotatez(-3deg)} `
                    );
                    // layerEl.style.backgroundColor = "yellow";
                    animTime = 3 + Math.random();
                    layerEl.style.transformOrigin = this.data.Settings && this.data.Settings.transformOrigin ? this.data.Settings.transformOrigin : "center top";
                    setTimeout( () => {
                        layerEl.style.animation = `sway_${ this.hashId } ${ animTime }s infinite alternate-reverse ease-in-out`;
                    }, Math.random() * 1000);
                    break;

                case 'lookaround':
                    this.addKeyFrames(
                        `lookaround_${ this.hashId }`,
                        `0% {transform: ${this.cssTransformValue} rotatez(1deg) rotatey(0deg)} ` + 
                        `100% {transform: ${ this.cssTransformValue } rotatez(-1deg) rotatey(4deg)} `
                    );
                    // layerEl.style.backgroundColor = "yellow";
                    animTime = 5 + Math.random();
                    layerEl.style.transformOrigin = this.data.Settings && this.data.Settings.transformOrigin ? this.data.Settings.transformOrigin : "center top";
                    setTimeout(() => {
                        layerEl.style.animation = `lookaround_${ this.hashId } ${ animTime }s infinite alternate-reverse ease-in-out`;
                    }, Math.random() * 2000);
                    break;
            }
        }
    },

};
</script>

<template>
    <div v-if=" data.Image " :id="`layer-${hashId}`" :style="styleValue" class="layer">
    </div>
</template>