export function getDomainFromUrl( url ) {
    const regex = /(?:https?:\/\/)?(?:www\.)?([^/]+)/;
    const match = url.match( regex );
    return match && match[ 1 ];
}

export function getYoutubeIdFromUrl( url ) {
    const regex = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/;
    const match = url.match( regex );
    return match && match[ 1 ];
}