<script>
import { useAppStore } from '@/store';

const normalize = ( va, mi, ma ) => ( va - mi ) / ( ma - mi ),
    interpolate = ( no, mi, ma ) => mi + ( ma - mi ) * no,
    // map = ( va, mi1, ma1, mi2, ma2 ) => interpolate( normalize( va, mi1, ma1 ), mi2, ma2 ),
    offset = 50, // dragging calculated with an edge to make it feel more comfortable
    smoothFactor = .28, // x and y are not directly set, but with a tendency towards that target (tx, ty)
    STATUS_IDLE = 1, // idle
    STATUS_DRAG = 2, // dragging
    STATUS_FADE = 3; // post dragging
    
let viewer, paper, glass,
    glassPos = { x: 0, y: 0, tx: 0, ty: 0 },
    glassBackPos = { x: 0, y: 0, tx: 0, ty: 0 },
    paperPos = { x: 0, y: 0, tx: 0, ty: 0 },
    status = STATUS_IDLE,
    TICKING = false;

export default {
    name: 'ContentLens',

    props: {
        selectedObject: {
            type: Object,
            required: true,
        },
    },

    computed: {
        title() {
            return this.selectedObject.Detail && this.selectedObject.Detail.Title
                ? this.selectedObject.Detail.Title
                : (this.selectedObject.Title || '');
        },

        paperStyle() {
            if (!this.selectedObject.Detail || !this.selectedObject.Detail.Image ) return '';

            const width = this.selectedObject.Detail.Image.width,
                height = this.selectedObject.Detail.Image.height,
                aspect = height / width;

            const styleProps = [];
            styleProps.push(`width: 100vw`);
            styleProps.push(`height: ${100 * aspect}vw`);
            styleProps.push(`background-image: url(${this.store.strapiUrl}${this.selectedObject.Detail.Image.url})`);
            
            return styleProps.join(';')
        },

        glassStyle() {
            return this.selectedObject.Detail && this.selectedObject.Detail.Image
                ? `background-image: url(${this.store.strapiUrl}${this.selectedObject.Detail.Image.url});`
                : 'background-image: none;';
        },
    },
    
    setup() {
        const store = useAppStore();

        return {
            store
        };
    },

    methods: {
        enter() {
            console.log('ContentLens enter');
            TICKING = true;
            this.tick();
        },

        exit() {
            console.log('ContentLens exit');
            TICKING = false;
        },

        touchstart () {
            if ( status === STATUS_DRAG ) return;
            status = STATUS_DRAG;
            if ( !glass.classList.contains( "active" ) ) glass.classList.add( "active" );
        },

        touchmove ( e ) {
            if ( status !== STATUS_DRAG ) return;

            e.preventDefault();

            const cx = e.type == 'touchmove' ? e.touches[ 0 ].clientX : e.clientX,
                cy = e.type == 'touchmove' ? e.touches[ 0 ].clientY : e.clientY,
                rect = viewer.getBoundingClientRect(),
                x = Math.min( viewer.clientWidth - offset, Math.max( offset, Math.min( viewer.clientWidth, cx - rect.left ) ) ),
                y = Math.min( viewer.clientHeight - offset, Math.max( offset, Math.min( viewer.clientHeight, cy - rect.top ) ) ),
                normX = normalize( x, offset, viewer.clientWidth - offset ),
                normY = normalize( y, offset, viewer.clientHeight - offset ),
                diffX = paper.clientWidth - viewer.clientWidth,
                diffY = paper.clientHeight - viewer.clientHeight;

            glassPos.tx = interpolate( normX, 0, viewer.clientWidth - glass.clientWidth );
            glassPos.ty = interpolate( normY, 0, viewer.clientHeight - glass.clientHeight );

            paperPos.tx = interpolate( normX, 0, diffX );
            paperPos.ty = interpolate( normY, 0, diffY );

            glassBackPos.tx = interpolate( normX, 0, 100 );
            glassBackPos.ty = interpolate( normY, 0, 100 );
        },

        touchend () {
            if ( status !== STATUS_DRAG ) return;
            status = STATUS_FADE;
            if ( glass.classList.contains( "active" ) ) glass.classList.remove( "active" );
            // console.log("touchend");
        },

        tick () {
            // repeat
            // console.log( '~' );
            if ( TICKING ) requestAnimationFrame( this.tick );

            // nothing on idle
            if ( status === STATUS_IDLE ) return;

            // return to idle when smoothing out is at its end
            if ( status === STATUS_FADE && Math.abs( glassPos.tx - glassPos.x ) < 0.2 ) status = STATUS_IDLE;

            // smooth motion towards targets
            paperPos.x += smoothFactor * ( paperPos.tx - paperPos.x );
            paperPos.y += smoothFactor * ( paperPos.ty - paperPos.y );
            glassPos.x += smoothFactor * ( glassPos.tx - glassPos.x );
            glassPos.y += smoothFactor * ( glassPos.ty - glassPos.y );
            glassBackPos.x += smoothFactor * ( glassBackPos.tx - glassBackPos.x );
            glassBackPos.y += smoothFactor * ( glassBackPos.ty - glassBackPos.y );

            // transformations
            paper.style.transform = `translate(${ -paperPos.x }px, ${ -paperPos.y }px)`;
            glass.style.transform = `translate(${ glassPos.x }px, ${ glassPos.y }px)`;
            glass.style.backgroundPosition = `${ glassBackPos.x }% ${ glassBackPos.y }%`;

        }
    },

    mounted() {
        viewer = document.getElementById( "viewer" );
        paper = document.getElementById( "paper" );
        glass = document.getElementById( "glass" );

        // const magnify = ( () => { } )();

        viewer.addEventListener( "touchstart", this.touchstart );
        viewer.addEventListener( "touchmove", this.touchmove );
        viewer.addEventListener( "touchend", this.touchend );
        viewer.addEventListener( "touchcancel", this.touchend );

        viewer.addEventListener( "mousedown", this.touchstart );
        viewer.addEventListener( "mousemove", this.touchmove );
        viewer.addEventListener( "mouseup", this.touchend );
    }
};

</script>

<template>
    <div class="detail-content">
        <div id="viewer" class="viewer">
            <div id="paper" class="paper" :style="paperStyle"></div>
            <div id="glass" class="glass" :style="glassStyle"></div>
        </div>
        <div class="shapes">
            <img class="tmpl-content-left-wig mbm-opacity" src="@/assets/contentwig-lens.svg">
        </div>
        <div class="content-block">
            <h1>{{ title }}</h1>
            <p v-html="selectedObject.Detail.BodyContent"></p>
        </div>
    </div>  
</template>

<style lang="scss">
.viewer {
    width: 100vw;
    height: 100vh;
    background-color: rgba(yellow, 0.4);
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 24px rgba(black, 0.13);

    .paper {
        background-size: 100vw auto;
        // opacity: 0.2;
    }

    // $glass_radius: 160px;
    $glass_radius: 200px;

    .glass {
        position: absolute;
        top: 0;
        left: 0;
        width: 400px;
        height: 400px;
        border-radius: $glass_radius;
        background-size: 200vw auto;
        pointer-events: none;
        box-shadow: inset -20px 20px 40px rgba(white, 0.4),
            inset 20px -20px 40px rgba(black, 0.2);
        opacity: 0.95;

        &::before,
        &::after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            border-radius: $glass_radius;
        }

        &::before {
            box-shadow: -6px 4px 4px rgba(black, 0.23);
        }

        &::after {
            // background-image: linear-gradient(21deg, transparent 50%, rgba(white, 0.3) 60%)
            background-image: url(@/assets/dirt.webp);
            background-size: cover;
            mix-blend-mode: soft-light;
            opacity: 0.9;
        }
    }
}
.shapes,
.content-block {
    pointer-events: none;
}
</style>