<script>
import { ref } from 'vue';
import { detect } from 'detect-browser';
import { useAppStore } from '@/store';
import StageObjectLayer from "@/components/StageObjectLayer.vue";

import { safeString } from '@/util/stringutils.js';

export default {
    name: "StageObject",

    components: {
        StageObjectLayer,
    },

    emits: [
        'objectClick',
        'videoEnded',
    ],

    props: {
        data: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            browser: { name: 'unknown', version: 0, os: 'unknown' },
            doNotResume: false,
        };
    },

    setup() {
        const store = useAppStore();

        const stageObject = ref( null );
        const videoElementPlayer = ref( null );

        return { 
            stageObject,
            store, 
            videoElementPlayer,
        };
    },

    computed: {
        videoId() {
            return 'so_video_' + safeString(this.data.Identifier);
        },
        isMandatory() {
            return "undefined" !== typeof this.data.Mandatory && this.data.Mandatory === true;
        },
        iconOffsetX() {
            return this.data.IconOffsetX || 0;
        },
        iconOffsetY() {
            return this.data.IconOffsetY || 0;
        },
        iconOffsetXComputed() {
            return this.iconOffsetX / 19.20 + 50;
        },
        iconOffsetYComputed() {
            return -this.iconOffsetY / 10.80;
        },
        titleOffsetX() {
            return this.data.TitleOffsetX || 0;
        },
        titleOffsetY() {
            return this.data.TitleOffsetY || 0;
        },
        titleOffsetXComputed() {
            return this.titleOffsetX / 19.20 + 50;
        },
        titleOffsetYComputed() {
            return -this.titleOffsetY / 10.80;
        },
        titleStyles() {
            const titlePosition = this.data.TitlePosition || 'Bottom';
            if (titlePosition === 'Right') return 'text-align: left; transform: translateX(0%)';
            if (titlePosition === 'Left') return 'text-align: right; transform: translateX(-100%)';
            if (titlePosition === 'Bottom') return 'text-align: center; transform: translateX(-50%)';
            return '';
        },
        styleDefinition() {
            const styles = [];
            if (this.data && this.data.Xpos) styles.push(`left: ${this.data.Xpos / 19.20}%`);
            if (this.data && this.data.Ypos) styles.push(`top: ${this.data.Ypos / 10.80}%`);
            return styles.join(';');
        },
    },

    methods: {
        // clickAreaTransformValue(area) {
        //     const respX = area.OffsetX / 1920;
        //     const respY = area.OffsetY / 1080;
        //     const percX = area.Width / respX * 100;
        //     const percY = area.Height / respY * 100;
        //     console.log(respX, respY)
        //     return `translate(` +
        //         `${ - 50 + percX }%,` +
        //         `${ percY }%` +
        //         `)`;
        // },

        clickAreaStylePosition(area) {
            const percX = area.OffsetX / 19.20;
            const percY = area.OffsetY / 10.80;
            // const percX = area.Width / respX * 100;
            // const percY = area.Height / respY * 100;
            return `
                left: ${50 + percX}%;
                bottom: ${-percY}%;
                transform: 
                    translate(-50%, 0) 
                    rotate(${area.Rotation || 0}deg) 
                    translateZ(10px);
                    ;
            `;
        },

        clickHandler(  ) {
            // console.log(this.data)
            this.$emit( "objectClick", this.data, this.stageObject );
        },

        pauseVideo() {
            // console.log( 'stageobject::pause' );
            if ( !this.data.Video || !this.videoElementPlayer ) return;
            this.videoElementPlayer.pause();
        },
        
        resumeVideo() {
            // console.log( 'stageobject::resume' );
            if ( this.doNotResume || !this.data.Video || !this.videoElementPlayer ) return;
            this.videoElementPlayer.play();
        },

        onVideoEnded() {
            console.log( 'stageobject::onVideoEnded' );
            this.$emit( "videoEnded", this.data );
            if (this.data.Settings && this.data.Settings.videoEndsExperience && this.data.Settings.videoEndsExperience === true) {
                this.doNotResume = true;
            }
        }
    },

    mounted() {
        this.browser = detect() || { name: 'unknown', version: 0, os: 'unknown' };

        // let count = 1;
        const int1 = setInterval(() => {
            if (this.data) {
                if (!this.data.Video) { clearInterval( int1 ); }
                if (this.videoElementPlayer) {
                    this.videoElementPlayer.style.visibility = 'hidden';
                    const vidBoundingClientRect = this.videoElementPlayer.getBoundingClientRect();
                    // console.log( '-', count );
                    // count++;
                    if (vidBoundingClientRect.width > 0 && vidBoundingClientRect.height > 0) {
                        clearInterval( int1 );
                        // console.log( this.videoElementPlayer );
                        // const vidBoundingClientRect = this.videoElementPlayer.getBoundingClientRect();
                        const vidScale = this.data.Settings && this.data.Settings.videoScaling ? this.data.Settings.videoScaling : 1;
                        // console.log( 'video!', vidBoundingClientRect, vidScale );
                        const w = ( vidBoundingClientRect.width / 19.20 ) * vidScale;
                        const h = ( vidBoundingClientRect.height / 10.80 ) * vidScale;
                        this.videoElementPlayer.style.width = `${w}%`;
                        this.videoElementPlayer.style.height = `${h}%`;
                        this.videoElementPlayer.style.visibility = 'visible';

                        this.videoElementPlayer.pause();

                        // console.log( this.data.Settings)
                        if (this.data.Settings && "undefined" !== typeof this.data.Settings.muteVideo && this.data.Settings.muteVideo === false) {
                            this.videoElementPlayer.muted = false;
                        }
                        if (this.data.Settings && "undefined" !== typeof this.data.Settings.loopVideo && this.data.Settings.loopVideo === false) {
                            this.videoElementPlayer.loop = false;
                        }

                        // 
                        this.videoElementPlayer.addEventListener( 'ended', this.onVideoEnded );

                        setTimeout(() => {
                            this.videoElementPlayer.play();

                            if ( window.DJS_SKIP_INTRO )
                            {
                                setTimeout( () => {
                                    if ( this.videoElementPlayer )
                                    {
                                        this.videoElementPlayer.currentTime = this.videoElementPlayer.duration - 3;
                                        // this.skipVideo();
                                    }
                                }, 500 );
                            }
                        }, 1000);
                    }
                }
           }
        }, 200);
    },

};
</script>

<template>
    <div 
        :style="styleDefinition" 
        :class="`object ${isMandatory ? 'mandatory' : ''}`" 
        ref="stageObject"
        >

        <!-- Layers -->
        <div v-if="data.Layers && data.Layers.length > 0" class="layers">
            <StageObjectLayer v-for="layer, index in data.Layers" :key="layer.id" :index="index" :data="layer"></StageObjectLayer>
        </div>

        <!-- Video -->
        <video :id="videoId" v-else-if="data.Video" ref="videoElementPlayer" class="videoElementPlayer" preload="metadata" autoplay muted loop playsinline>
            <source v-if=" browser.name == 'safari' || browser.name == 'ios' "
                :src=" `${store.strapiUrl}${data.Video.VideoHEVC.url}` " type="video/mp4" codecs="hvc1">
            <source v-else-if=" browser.name != 'unknown' " :src=" `${store.strapiUrl}${data.Video.VideoVP9.url}` "
                type="video/webm">
        </video>

        <!-- Icons -->
        <div v-if=" data.Type !== 'Decoration' && data.Type !== 'Person' && !(data.Settings && data.Settings.hideIcon && data.Settings.hideIcon === true) "
            :class=" `icon icon-${data.Type.toLowerCase()}` " 
            :style=" `left: ${iconOffsetXComputed}%; bottom: ${iconOffsetYComputed}%` "
            @click=" clickHandler "
            >
        </div>

        <!-- Person titles -->
        <div v-if=" data.Type === 'Person' " 
            class="person-title"
            :style="`left: ${titleOffsetXComputed}%; bottom: ${titleOffsetYComputed}%; ${titleStyles};` "
            @click="clickHandler"
            >
            {{ data.Title }}
        </div>

        <!-- Click Areas -->
        <!--<div v-if="data.ClickAreas && data.Type !== 'Decoration'" class="click-areas">-->
        <div v-if="data.ClickAreas" class="click-areas">
            <div v-for="area in data.ClickAreas" 
                :key="area.id" 
                :style="`
                    width: ${area.Width / 19.20}%; 
                    height: ${area.Height / 10.80}%; 
                    ${clickAreaStylePosition(area)}
                    `" 
                    @click=" clickHandler "
                    class="click-area"
                    ></div>
        </div>
        <!-- transform: ${clickAreaTransformValue(area)} rotate(${area.Rotation || 0}deg);`" -->
    </div>
</template>