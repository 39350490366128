import { createWebHistory, createRouter } from "vue-router";
import LandingView from "@/views/LandingAnimView.vue";
import DeJoodseStraatView from "@/views/DeJoodseStraatView.vue";
import OverView from "@/views/OverView.vue";
import ExtrasView from "@/views/ExtrasView.vue";
import ErrorView from "@/views/ErrorView.vue";
import BronnenView from "@/views/BronnenView.vue";
import InfopageView from "@/views/InfopageView.vue";

const routes = [
  {
    path: "/",
    name: "LandingView",
    meta: {
      class: "landing",
    },
    component: LandingView,
  },
  {
    path: "/start",
    name: "De Joodse Straat",
    meta: {
      class: "experience",
    },
    component: DeJoodseStraatView,
  },
  {
    path: "/over",
    name: "Over",
    meta: {
      class: "page over",
    },
    component: OverView,
  },
  {
    path: "/extra",
    name: "Extra informatie",
    meta: {
      class: "page extra",
    },
    component: ExtrasView,
  },
  {
    path: "/bronnen",
    name: "Bronnen",
    meta: {
      class: "page bronnen",
    },
    component: BronnenView,
  },
  {
    path: "/info/:slug",
    name: "InfoPage",
    meta: {
      class: "page info",
    },
    component: InfopageView,
  },
  { 
    path: '/404',
    name: "404",
    meta: {
      class: "page 404",
    },
    component: ErrorView 
  },
  { 
    path: '/:catchAll(.*)', 
    // redirect: '/404' 
    meta: {
      class: "page 404",
    },
    component: ErrorView 
  }
];

const router = createRouter( {
  history: createWebHistory(),
  routes,
} );

export default router;