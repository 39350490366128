<script>
export default {
    name: 'BackPlate',
    
    props: {
        classes: {
            type: String,
            default: ''
        },
        bgimg: {
            type: String,
            default: ''
        }
    },    
}
</script>

<template>
    <div :class="classes" :style="`background-image: url(${bgimg})`">
    </div>
</template>