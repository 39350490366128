<script>
import { useAppStore } from '@/store';

import anime from 'animejs/lib/anime.es.js';

const STATUS_IDLE = 1, // idle
    STATUS_DRAG = 2, // dragging
    STATUS_FADE = 3; // post dragging

let tutorialAnime,
    cContainer,
    cImg1,
    cImg2,
    cHandle,
    // cMin,
    // cMax,
    // cPos = 0,
    factor,
    status = STATUS_IDLE;
    // TICKING = false;

export default {
    name: 'CompareRight',

    props: {
        selectedObject: {
            type: Object,
            required: true,
        },
    },

    setup() {
        const store = useAppStore();

        return {
            store
        };
    },

    computed: {
        title() {
            return this.selectedObject.Detail && this.selectedObject.Detail.Title
                ? this.selectedObject.Detail.Title
                : ( this.selectedObject.Title || '' );
        },
    },

    methods: {
        dragSetup() {
            const w = window, 
            d = document, 
            e = d.documentElement, 
            g = d.getElementsByTagName( 'body' )[ 0 ], 
            sw = w.innerWidth || e.clientWidth || g.clientWidth, 
            sh = w.innerHeight || e.clientHeight || g.clientHeight,
            // const appRect = document.getElementById( 'app' ).getBoundingClientRect(),
                // appRectAspect = appRect.width / appRect.height,
                refImage = this.selectedObject.Detail.Image,
                // refImageAspect = refImage.width / refImage.height,
                // screenIsLandscape = appRectAspect >= 1,
                imageIsLandscape = ( refImage.width / refImage.height ) >= 1;
            // console.log( appRect, refImage );

            let offsetX = 0,
                offsetY = 0;
            if ( imageIsLandscape )
            { // height determines
                factor = sh / refImage.height;
                offsetX = ( sw - ( refImage.width * factor ) ) / 2;
            } else
            { // width determines
                factor = sw / refImage.width;
                offsetY = ( sh - ( refImage.height * factor ) ) / 2;
            }

            // cMin = Math.abs( offsetX );
            // cMax = cMin + appRect.width;

            cContainer.style.width = `${ refImage.width * factor }px`;
            cContainer.style.height = `${ refImage.height * factor }px`;
            cContainer.style.transform = `translate(${ offsetX }px, ${ offsetY }px)`;
            cImg1.style.backgroundSize = `${ refImage.width * factor }px ${ refImage.height * factor }px`;
            cImg2.style.backgroundSize = `${ refImage.width * factor }px ${ refImage.height * factor }px`;
        },

        drag(cx) {
            // const container_rect = cContainer.getBoundingClientRect();
            // const handle_rect = cHandle.getBoundingClientRect();
            // cPos = handle_rect.x;
            // console.log( cx );
            cImg2.style.width = `${ cx }px`;
            cHandle.style.transform = `translateX(${ cx }px)`;
        },

        enter() {
            console.log( 'CompareRight enter' );
            // TICKING = true;
            // this.tick();
            
            this.dragSetup();
            anime( {
                    targets: []
            });

            const refImage = this.selectedObject.Detail.Image,
                width = refImage.width * this.factor;
            
            // console.log( refImage, factor, width);
            cHandle.style.left = `0px`;
            cHandle.style.transform = `translateX(${ 0.5 * width }px)`;

            this.drag(0);
            let anm = {x:0};
            status = STATUS_DRAG;
            tutorialAnime = anime( {
                targets: anm,
                x: 500,
                duration: 1000,
                easing: 'easeInOutSine',
                direction: 'alternate',
                delay: 1000,
                update: () => this.drag( anm.x ),
                complete: () => status = STATUS_FADE
            } );

        },

        exit() {
            console.log( 'CompareRight exit' );
            // TICKING = false;
        },

        touchstart() {
            if ( tutorialAnime ) tutorialAnime.pause();
            if ( status === STATUS_DRAG ) return;
            status = STATUS_DRAG;
        },

        touchmove( e ) {
            if ( status !== STATUS_DRAG ) return;

            e.preventDefault();

            const cx = e.type == 'touchmove' ? e.touches[ 0 ].clientX : e.clientX;

            // console.log( cx );
            this.drag(cx);
        },

        touchend() {
            if ( status !== STATUS_DRAG ) return;
            status = STATUS_FADE;
        },

        // tick() {
        //     // repeat
        //     // console.log( '~' );
        //     if ( TICKING ) requestAnimationFrame( this.tick );

        //     // nothing on idle
        //     if ( status === STATUS_IDLE ) return;
        // },

        imgStyle(imageId) {
            if (!this.selectedObject.Detail[ imageId ] ) return "";
            return `background-image: url(${ this.store.strapiUrl }${ this.selectedObject.Detail[ imageId ].url })`;
        }
    },

    mounted() {
        cContainer = this.$refs.compare_container;
        cHandle = this.$refs.compare_handle;
        cImg1 = this.$refs.compare_img1;
        cImg2 = this.$refs.compare_img2;

        // cImg1.style.backgroundImage = `url(${ this.store.strapiUrl }${ this.selectedObject.Detail.Image.url })`;
        // cImg2.style.backgroundImage = `url(${ this.store.strapiUrl }${ this.selectedObject.Detail.Image2.url })`;

        window.addEventListener( "resize", () => this.dragSetup() );

        this.dragSetup();

        cContainer.addEventListener( "touchstart", this.touchstart );
        cContainer.addEventListener( "touchmove", this.touchmove );
        cContainer.addEventListener( "touchend", this.touchend );
        cContainer.addEventListener( "touchcancel", this.touchend );

        cContainer.addEventListener( "mousedown", this.touchstart );
        cContainer.addEventListener( "mousemove", this.touchmove );
        cContainer.addEventListener( "mouseup", this.touchend );

    },
};

</script>
<template>
    <div class="detail-content">
        <div class="compare-backdrop" :style="imgStyle( 'Image' )"></div>
        <div ref="compare_container" class="compare-container" id="compare-container">
            <div ref="compare_img1" :style="imgStyle( 'Image' )" class="compare-img" id="compare-img1"></div>
            <div ref="compare_img2" :style="imgStyle( 'Image2' )" class="compare-img" id="compare-img2" style="width: 0px;"></div>
            <div ref="compare_handle" class="compare-handle">
                <div class="compare-handle__button"></div>
            </div>
        </div>
        <div class="shapes">
            <img class="tmpl-compare-right-wig mbm-multiply" src="@/assets/contentwig-r.svg">
            <img class="tmpl-compare-right-wig mbm-opacity" src="@/assets/contentwig-r.svg">
        </div>
        <div class="content-block">
            <h1>{{ title }}</h1>
            <p v-html="selectedObject.Detail.BodyContent"></p>
        </div>
    </div>
</template>