<script>
import { useAppStore } from '@/store';
import { useRoute } from 'vue-router';


export default {
    
    computed: {
        isExperiencePage() {
            const route = useRoute();
            return route.path == '/start';
        },
        version() { return `${ require( '../../package.json' ).version }-${ process.env.NODE_ENV }`; },
        chapters() { return this.store.hasContent ? this.store.app_content.chapters : []; },
    },

    methods: {
        chapterClickHandler( event ) {
            event.preventDefault();
            console.log( "NavComponent::chapterClickHandler", event.currentTarget.dataset.chapter || "-" );
            
            // this.store.setActiveChapterNumber( event.currentTarget.dataset.chapter );

            this.$emit( 'clickChapter', event.currentTarget.dataset.chapter );

            document.getElementById( "togglenav" ).checked = false;
            document.getElementById( "togglenav" ).dispatchEvent( new Event( 'change' ) );

            this.$gtag.event( 'click', { target: 'Chapter', chapter: event.currentTarget.dataset.chapter, context: 'Menu' } );

            // console.log( "DeJoodseStraat::chapterClickHandler", event.currentTarget.dataset.index, event.currentTarget.dataset.chapter );
            // this.$emit( 'chapterClick', event.currentTarget.dataset.index, event.currentTarget.dataset.chapter );
        },
    },

    setup() {
        const store = useAppStore();
        return { store };
    },

    mounted() {
        document.getElementById( 'menu-panel' ).querySelectorAll( "ul > li > a" ).forEach( ( a ) => {
            a.addEventListener( "click", ( event ) => {
                if ( event.currentTarget.classList.contains( 'chapter' ) )
                {
                    event.preventDefault();
                    console.log( event.currentTarget.dataset.chapter );
                }
                document.getElementById( "togglenav" ).checked = false;
                document.getElementById( "togglenav" ).dispatchEvent( new Event( 'change' ) );
            } );
        } );
        // on toggle checkbox 
        document.getElementById( 'togglenav' ).addEventListener( "change", ( event ) => {
            if ( event.currentTarget.checked )
            {
                document.body.classList.add( 'menu-open' );
                this.$emit( 'menuAction', 'open' );
            }
            else
            {
                document.body.classList.remove( 'menu-open' );
                this.$emit( 'menuAction', 'close' );
            }
        } );
    }
}
</script>

<template>
    <nav class="main">
        <input class="menu-trigger hidden" id="togglenav" type="checkbox">
        <label class="menu-button" for="togglenav">
            <div class="hamburger"></div>
        </label>
        <section id="menu-panel" class="menu-panel" data-nosnippet>
            <ul>
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>
                    <router-link to="/start">De Joodse Straat</router-link>
                </li>
                <li v-for="chapter, key, index in chapters" :key="index" :class=" `chapter ${isExperiencePage ? 'show' : ''}` ">
                    <a class="chapter" :data-chapter="chapter.Number" :data-index=" index " href="/" @click=" chapterClickHandler ">{{ chapter.Number }}. {{ chapter.Title }}</a>
                </li>
                <li v-if="isExperiencePage" :class=" `chapter show spacer` "></li>
                <li>
                    <router-link to="/extra">Extra</router-link>
                </li>
                <li>
                    <router-link to="/over">Over</router-link>
                </li>
                <!--<li>
                    <router-link to="/bronnen">Bronnen</router-link>
                </li>-->
                <li class="v">
                    <div class="v">{{ version }}</div>
                </li>
            </ul>
        </section>
    </nav>
</template>