<script>
// import { ref } from 'vue';
import { detect } from 'detect-browser';
import { useAppStore } from '@/store';

export default {
    name: "DetailExitButton",

    components: {
    },

    props: {
        selectedObject: {
            type: Object,
            required: true,
        },
        // index: {
        //     type: Number,
        //     required: true,
        // },
        template: {
            type: String,
            required: true,
            default: '',
        },
    },

    emits: [
    ],

    data() {
        return {
            // browser: { name: 'unknown', version: 0, os: 'unknown' },
        };
    },

    setup() {
        const store = useAppStore();

        return {
            store,
        };
    },

    computed: {

        exitButtonStyle() {
            const styles = [];
            if ( ![ 'PersonHorizontal', 'ExtraPerson' ].includes( this.template ) )
            {
                if ( this.selectedObject.Detail && this.selectedObject.Detail.BackPosX && this.selectedObject.Detail.BackPosX != "" )
                {
                    styles.push( ` left: ${ this.selectedObject.Detail.BackPosX / 19.20 }vw; ` );
                }
                if ( this.selectedObject.Detail && this.selectedObject.Detail.BackPosY && this.selectedObject.Detail.BackPosY != "" )
                {
                    styles.push( ` top: ${ this.selectedObject.Detail.BackPosY / 19.20 }vw; ` );
                }
            }
            return styles.join( '' );
        },

        isPersonTemplate() {
            // return [ 'PersonVertical', 'PersonHorizontal' ].includes( this.template );
            return [ 'ExtraPerson', 'PersonHorizontal' ].includes( this.template );
        },

        hasLayer() {
            return this.selectedObject.Layers && this.selectedObject.Layers.length > 0;
        },

        isGraphicalExit() {
            return ![ 'CompareRight', 'Lens' ].includes( this.template ) && !this.isPersonTemplate && this.hasLayer;
        },

        graphicLayerStyle() {
            const styles = [];
            // return `background-image: url(${this.graphicLayer});`;
            // `background-image: url(${ graphicLayer })`;
            const layerIndex = this.selectedObject.Detail && this.selectedObject.Detail.Settings && this.selectedObject.Detail.Settings.backButtonLayerIndex ? this.selectedObject.Detail.Settings.backButtonLayerIndex : 0;
            // console.log( "graphicLayerStyle", layerIndex, this.selectedObject.Detail.Settings)
            if ( this.hasLayer )
            {
                styles.push( `background-image: url(${ this.store.strapiUrl }${ this.selectedObject.Layers[ layerIndex ].Image.url });` );
                // styles.push(` width: ${ this.selectedObject.Layers[ 0 ].Image.width / 19.20 }vw; `);
                // styles.push(` height: ${ this.selectedObject.Layers[ 0 ].Image.height / 19.20 }vw; `);
                styles.push( ` width: ${ this.selectedObject.Layers[ layerIndex ].Image.width }px; ` );
                styles.push( ` height: ${ this.selectedObject.Layers[ layerIndex ].Image.height }px; ` );

                const transforms = [];
                if ( this.selectedObject.Detail && this.selectedObject.Detail.Settings && this.selectedObject.Detail.Settings.backbuttonOffsetX && this.selectedObject.Detail.Settings.backbuttonOffsetX != "" )
                {
                    transforms.push( `translateX( ${ this.selectedObject.Detail.Settings.backbuttonOffsetX }% )` );
                }
                if ( this.selectedObject.Detail && this.selectedObject.Detail.Settings && this.selectedObject.Detail.Settings.backbuttonOffsetY && this.selectedObject.Detail.Settings.backbuttonOffsetY != "" )
                {
                    transforms.push( `translateY( ${ this.selectedObject.Detail.Settings.backbuttonOffsetY }% )` );
                }
                if ( this.selectedObject.Detail && this.selectedObject.Detail.Settings && this.selectedObject.Detail.Settings.backbuttonScale && this.selectedObject.Detail.Settings.backbuttonScale != "" )
                {
                    transforms.push( `scale( ${ this.selectedObject.Detail.Settings.backbuttonScale } )` );
                }
                if (transforms.length > 0) {
                    console.log( "transforms", transforms );
                    styles.push( `transform: ${ transforms.join( ' ' ) };` );
                }
            }
            return styles.join( '' );
        },
    },

    methods: {
    },

    mounted() {
        this.browser = detect() || { name: 'unknown', version: 0, os: 'unknown' };
        // console.log(this.data.Title);
    },

};
</script>

<template>
    <div class="exit-button" :style="exitButtonStyle" @click="$emit( 'exitDetail' )" >
        <div v-if="isGraphicalExit" class="graphic" :style="graphicLayerStyle"></div>
        <svg class="exit-button" xmlns="http://www.w3.org/2000/svg" width="78" height="78" >
            <defs>
                <filter id="prefix__a" width="78" height="78" x="0" y="0" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" />
                    <feGaussianBlur result="b" stdDeviation="3" />
                    <feFlood flood-opacity=".161" />
                    <feComposite in2="b" operator="in" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g filter="url(#prefix__a)">
                <circle cx="30" cy="30" r="30" fill="#fbd330" transform="translate(9 6)" />
            </g><text font-family="ImaginaryFriendBB-BoldItalic, ImaginaryFriend BB" font-size="30" font-style="italic"
                font-weight="700" transform="matrix(-.998 .07 -.07 -.998 46.452 26.821)">
                <tspan x="0" y="0">&gt;</tspan>
            </text>
        </svg>
        <!--<svg v-if="isPersonTemplate" class="person-close" xmlns="http://www.w3.org/2000/svg" width="78" height="78">
            <defs>
                <filter id="a" width="78" height="78" x="0" y="0" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" />
                    <feGaussianBlur result="blur" stdDeviation="3" />
                    <feFlood flood-opacity=".161" />
                    <feComposite in2="blur" operator="in" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g data-name="Component 7 – 7">
                <g filter="url(#a)">
                    <circle cx="30" cy="30" r="30" fill="#0e273e" data-name="Ellipse 1" transform="translate(9 6)" />
                </g>
                <path fill="#fbd302"
                    d="M53.377 48.694a.8.8 0 0 1 0 1.273l-1.655 1.655a.8.8 0 0 1-1.272 0l-11.01-11.01a.318.318 0 0 0-.51 0L28.177 51.367a.8.8 0 0 1-1.273 0l-1.655-1.654a.8.8 0 0 1 0-1.273l10.755-10.755a.318.318 0 0 0 0-.51l-11.01-11.01a.8.8 0 0 1 0-1.272l1.655-1.655a.8.8 0 0 1 1.273 0l11.01 11.01a.318.318 0 0 0 .509 0L50.768 22.92a.8.8 0 0 1 1.272 0l1.655 1.655a.8.8 0 0 1 0 1.273L42.367 37.176a.318.318 0 0 0 0 .509Z"
                    data-name="Path 27" />
            </g>
        </svg>-->
    </div>
</template>