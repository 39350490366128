<script>
import ProgressBarChapterObject from './ProgressBarChapterObject.vue';

export default {
    name: "ProgressBarChapter",

    components: {
        ProgressBarChapterObject,
    },

    props: {
        chapter: {
            type: Object,
            required: true,
        },
        active: {
            type: Boolean,
            required: true,
            default: false,
        },
    },

    computed: {
        mandatory() {
            return this.chapter.Objects.filter((obj) => "undefined" !== typeof obj.Mandatory && obj.Mandatory === true);
        },
    },

    methods: {
        chapterClickHandler(  ) {
            // console.log( "ProgressBarChapter::chapterClickHandler", this.chapter.Number || "-" );
            if (this.active) return;
            // this.setBarWidth();
            this.$emit( "chapterClick", this.chapter.Number );
        },

        resetBarWidth() {
            const chapterEl = document.getElementById( `chapter-${ this.chapter.Number }` );
            chapterEl.style.width = "45px";
        },

        setBarWidth() {
            // Create a clone to calculate width
            const chapterEl = document.getElementById( `chapter-${ this.chapter.Number }` );
            const clone = chapterEl.cloneNode( true );
            clone.id = `clone-${ this.chapter.Number }`;
            clone.classList.add( 'chapter', 'active', 'calculator' );
            chapterEl.parentNode.appendChild( clone );

            // Wait for clone to be rendered and Vue to be finished
            setTimeout(() => {
                // Get width
                const w = Math.ceil( clone.getBoundingClientRect().width );
    
                // Remove clone
                chapterEl.parentNode.removeChild( clone );
    
                // Set width
                chapterEl.style.width = `${ w }px`;

            }, 500)
        }
    },

    mounted() {
        // console.log( "ProgressBarChapter::mounted", this.mandatory );
    }

};
</script>

<template>
    <div :id="`chapter-${chapter.Number}`" :class=" `chapter ${active ? 'active' : ''}` " data-nosnippet>
        <div class=" chapter-el chapter-number" :onclick=" chapterClickHandler ">
            <div class=" chapter-number-inner">
                {{ chapter.Number }}
            </div>
        </div>
        <div class="chapter-el chapter-title">{{ chapter.Title }}</div>
        <div class="chapter-el chapter-items">
            <ProgressBarChapterObject v-for="obj in mandatory" :key=" obj.Identifier" :hasVisited="obj.hasVisited" :identifier="obj.Identifier"></ProgressBarChapterObject>
        </div>
    </div>
</template>