<script>
import { useAppStore } from '@/store';

export default {
    name: 'ContentLeft',

    props: {
        selectedObject: {
            type: Object,
            required: true,
        },
    },

    computed: {
        title() {
            return this.selectedObject.Detail && this.selectedObject.Detail.Title
                ? this.selectedObject.Detail.Title
                : (this.selectedObject.Title || '');
        },
    },
    
    setup() {
        const store = useAppStore();

        return {
            store
        };
    }
};

</script>
<template>
    <div class="detail-content">
        <div class="shapes">
            <img class="tmpl-content-left-wig mbm-multiply mbm-primary" src="@/assets/contentwig-l.svg">
            <img class="tmpl-content-left-wig mbm-opacity mbm-primary" src="@/assets/contentwig-l.svg">
            <img class="tmpl-content-left-wig mbm-multiply mbm-secondary" src="@/assets/contentwig-lens.svg">
            <img class="tmpl-content-left-wig mbm-opacity mbm-secondary" src="@/assets/contentwig-lens.svg">
        </div>
        <div class="content-block">
            <h1>{{ title }}</h1>
            <p v-html="selectedObject.Detail.BodyContent"></p>
        </div>
    </div>  
</template>