<script>
import { ref, watch } from 'vue';
import { useAppStore, EXP_STATES } from '@/store';
import { print } from '@/util/debug';
import { safeString } from '@/util/stringutils.js';
import VideoPlayerProxy from '@/util/videoplayerproxy.js';

import DetailView from '@/views/DetailView.vue';
import BackPlate from '@/components/BackPlate.vue';
import ColorPlate from '@/components/ColorPlate.vue';
import ProgressBar from "@/components/ProgressBar.vue";
import StageBackground from "@/components/StageBackground.vue";
import StageRoot from "@/components/StageRoot.vue";
import VideoPlayer from '@/components/VideoPlayer.vue';
import SubtitlePlayer from '@/components/SubtitlePlayer.vue';
import VideoEventPlayer from '@/components/VideoEventPlayer.vue';
import WedgesPlate from '@/components/WedgesPlate.vue';
import EndModal from '@/components/EndModal.vue';

const SCENE_TRANSITION_DURATION_IN = 1400;
const SCENE_TRANSITION_DURATION_OUT = 700;

let _detailActionTimeout = null;

export default {

  name: 'DeJoodseStraat',

  emits: ['clickChapter'],

  components: {
    BackPlate,
    ColorPlate,
    DetailView,
    EndModal,
    ProgressBar,
    StageBackground,
    // StageObject,
    StageRoot,
    SubtitlePlayer,
    VideoEventPlayer,
    VideoPlayer,
    WedgesPlate,
  },

  setup() {
    const store = useAppStore();

    const backplate = ref(null);
    const colorPlate = ref(null);
    const detail = ref(null);
    const eventsPlayer = ref(null);
    const objects = ref(null);
    const progressBar = ref(null);
    const stage = ref(null);
    const stagebackground = ref(null);
    const subtitlePlayer = ref(null);
    const videoPlayer = ref(null);
    const skipVideoBtn = ref(null);
    const wedgesplate = ref(null);

    watch(() => store.getExperienceState(), (newValue, oldValue) => {
      // console.log( "DeJoodseStraat|watch|state", oldValue, "-->", newValue );
      document.body.classList.remove(`state-${oldValue}`, `chapter-1`, `chapter-2`, `chapter-3`, `chapter-4`, `chapter-5`);
      document.body.classList.add(`state-${newValue}`);
      document.body.classList.add(`chapter-${store.activeChapterNumber}`);
    });

    return {
      backplate,
      colorPlate,
      detail,
      eventsPlayer,
      mouseX: 0,
      mouseY: 0,
      objects,
      objectsEl: null,
      progressBar,
      selectedObject: null,
      stage,
      stagebackground,
      store,
      subtitlePlayer,
      // videoEl: null,
      videoPlayer,
      videoPlayerProxy: null,
      skipVideoBtn,
      wedgesplate,
    };
  },

  computed: {
    activeChapterNumber() { return this.store.activeChapterNumber; },
    chapter() { return this.store.hasContent ? this.store.getChapterByNumber(this.activeChapterNumber) : null; },
    chapters() { return this.store.hasContent ? this.store.app_content.chapters : []; },
    chapterBackground() { return this.store.hasContent ? this.store.strapiUrl + this.store.app_content.settings.ChapterBackground.url : ''; },
    chapterSettings() { return this.chapter && this.chapter.Settings ? this.chapter.Settings : null; },

    hasVideo() { return this.chapter && this.chapter.IntroVideo; },
    hasSubtitles() { return this.chapter && this.chapter.IntroVideo && this.chapter.Settings && this.chapter.Settings.introVideoSubtitles; },
    hasEvents() { return this.chapter && this.chapter.IntroVideo && this.chapter.Settings && this.chapter.Settings.introVideoEvents; },

    experienceState() { return this.store.getExperienceState(); },

    isExperienceEnded() { return this.experienceState == EXP_STATES.ENDED; },

    colorPlateStyle() {
      const styles = [];
      if (this.chapterSettings && this.chapterSettings.colorPlateStyle ) {
        styles.push(`background-color: ${this.chapterSettings.colorPlateStyle }`);
      }
      return styles.join(';');
    },

    subtitleStyle() {
      const styles = [];
      if (this.chapterSettings && this.chapterSettings.subtitleColor ) {
        styles.push(`color: ${this.chapterSettings.subtitleColor }`);
      }
      return styles.join(';');
    },
  },

  methods: {

    experienceClasses(baseClass, extraClasses = []) {
      const exp_state = this.store.getExperienceState();
      const classes = [...[baseClass], ...extraClasses];

      if (exp_state === EXP_STATES.INTRO
        || exp_state === EXP_STATES.SCENE_TO_INTRO
        || exp_state === EXP_STATES.DETAIL_TO_INTRO
      ) {
        classes.push(`${baseClass}-intro`);
      }
      else if (exp_state === EXP_STATES.INTRO_TO_SCENE
        || exp_state === EXP_STATES.SCENE
        || exp_state === EXP_STATES.DETAIL_TO_SCENE
      ) {
        classes.push(`${baseClass}-scene`);
      }
      else if (exp_state === EXP_STATES.SCENE_TO_DETAIL
        || exp_state === EXP_STATES.DETAIL
      ) {
        classes.push(`${baseClass}-detail`);
      }
      else if (exp_state === EXP_STATES.ENDED
      ) {
        classes.push(`${baseClass}-ended`);
      }

      return classes.join(' ');
    },

    resizeExec() {
      // stageBoundingClientRect = stage.getBoundingClientRect();
      // const w = stageBoundingClientRect.width + 2 * CANVAS_BLEED
    },

    // objectClickHandler( chapterObject, chapterObjectEl ) {
    objectClickHandler(chapterObject) {
      if (chapterObject.Type === 'Decoration') {
        return;
      }

      if (this.store.getExperienceState() !== EXP_STATES.SCENE) {
        console.log('Not in scene');
        print('-');
        return;
      }

      // clear timeout for action 
      if (_detailActionTimeout) {
        clearTimeout(_detailActionTimeout);
        _detailActionTimeout = null;
      }

      // console.log( 'DeJoodseStraat|objectClickHandler', chapterObject );
      print(`${chapterObject.Identifier} (${chapterObject.Type})`);

      // Calc position 
      // const chapterObjectElBoundingClientRect = chapterObjectEl.getBoundingClientRect();
      // console.log( chapterObjectEl );
      // return;

      // Pause videos 
      !this.videoPlayerProxy || (this.videoPlayerProxy.isPlaying = false);
      this.stage.pauseVideoElements();

      // Set selected object
      this.selectedObject = chapterObject;

      // Will trigger visited in progressbar
      this.store.setChapterObjectVisited(chapterObject);

      // Update state for transition to detail
      this.store.setExperienceState(EXP_STATES.SCENE_TO_DETAIL);

      // Zoom and pan stage to object
      // this.stage.$el.style.transform = `
      //   scale(2.4) 
      //   translate( -${ 0.5 * chapterObjectEl.offsetLeft }px, -${ 0.25 * chapterObjectEl.offsetTop }px)
      //   `;

      // Post anim (just before)
      _detailActionTimeout = setTimeout(() => {
        this.detail.postEnter();
      }, SCENE_TRANSITION_DURATION_IN * 0.75);

      // Update state to detail
      _detailActionTimeout = setTimeout(() => {
        this.store.setExperienceState(EXP_STATES.DETAIL);
      }, SCENE_TRANSITION_DURATION_IN);

      this.$gtag.event( 'click', { target: 'Object', Identifier: chapterObject.Identifier ?? "Onbekend", context: `Chapter ${this.activeChapterNumber}` } );
    },

    chapterClickHandler(chapterNum) {
      if (!chapterNum || chapterNum === this.activeChapterNumber) return;

      if (this.videoPlayer) {
        this.videoPlayer.stop();
      }

      this.$emit('clickChapter', chapterNum);

      this.$gtag.event( 'click', { target: 'Chapter', chapter: chapterNum, context: 'ProgressBar' } );
    },

    startChapter() {
      console.log('DeJoodseStraat|startChapter', this.store.activeChapterNumber);

      if (this.subtitlePlayer) {
        this.subtitlePlayer.reset();
        this.subtitlePlayer.unsetStageVideoPlayer();
        this.subtitlePlayer.unsetStageSubtitles();
      }

      this.objectsEl = document.getElementById('objectsEl');
      if (this.objectsEl) this.objectsEl.style.visibility = 'visible';
      this.bgObjectsEl = document.getElementById('bgObjectsEl');
      if (this.bgObjectsEl) this.bgObjectsEl.style.visibility = 'visible';

      // Determine wait time 
      const timeout = [EXP_STATES.SCENE, EXP_STATES.DETAIL].includes(this.store.getExperienceState()) ? SCENE_TRANSITION_DURATION_IN : 100;

      if (this.store.getExperienceState() === EXP_STATES.SCENE) {
        this.store.setExperienceState(EXP_STATES.SCENE_TO_INTRO);
      }
      else if (this.store.getExperienceState() === EXP_STATES.DETAIL) {
        this.store.setExperienceState(EXP_STATES.DETAIL_TO_INTRO);
      }

      setTimeout(() => {

        // Set progressbar width 
        this.progressBar.updateCurrentBarWidth();

        // Start intro video
        if (this.chapter && this.chapter.IntroVideo) {
          this.store.setExperienceState(EXP_STATES.INTRO);
          // document.body.classList.add( 'intro-playing' );
          
          !this.videoPlayerProxy || ( this.videoPlayerProxy.isPlaying = false );
          this.stage.pauseVideoElements();

          // this.videoEl = document.getElementById('videoEl');
          // this.videoEl.style.visibility = 'visible';
          this.videoPlayer.fadeIn();
          this.videoPlayer.play();

          if ( this.subtitlePlayer ) this.subtitlePlayer.start();

          // ########### DEBUG ########### 
          if (window.DJS_SKIP_INTRO) {
            setTimeout(() => {
              if (this.videoPlayer) {
                this.skipVideo();
              }
            }, 500);
          }
          // ########### /DEBUG ########### 

        }

        // When no intro video 
        else {
          this.store.setExperienceState(EXP_STATES.SCENE);

          this.startPotentialStageSubtitles();
        }

      }, timeout);
    },

    pauseChapter() {
      // console.log( 'DeJoodseStraatView::pauseChapter' );
      // Pause intro
      if (this.store.getExperienceState() === EXP_STATES.INTRO) {
        this.videoPlayer.pause();
        this.store.setExperienceState(EXP_STATES.PAUSED);
      }

      // Pause detail
      if (this.store.getExperienceState() === EXP_STATES.DETAIL) {
        this.detail.pause();
      }

      !this.videoPlayerProxy || ( this.videoPlayerProxy.isPlaying = false );
      this.stage.pauseVideoElements();
    },

    resumeChapter() {
      // console.log( 'resumeChapter' );
      // Resume intro
      if (this.store.getExperienceState() === EXP_STATES.PAUSED) {
        this.videoPlayer.resume();
        this.store.setExperienceState(EXP_STATES.INTRO);
      }

      // Resume detail
      if (this.store.getExperienceState() === EXP_STATES.DETAIL) {
        this.detail.resume();
      }

      !this.videoPlayerProxy || ( this.videoPlayerProxy.isPlaying = true );
      this.stage.resumeVideoElements();
    },

    videoEndHandler() {
      // console.log( 'videoEndHandler' );

      // If in intro, transition to scene 
      if (this.store.getExperienceState() === EXP_STATES.INTRO) {
        if (this.subtitlePlayer ) {
          this.subtitlePlayer.reset();
          this.subtitlePlayer.unsetStageVideoPlayer();
          this.subtitlePlayer.unsetStageSubtitles();
        }
        this.store.setExperienceState(EXP_STATES.INTRO_TO_SCENE);

        let completedNum = 0;
        document.querySelectorAll('.objects .object').forEach((el, i) => {
          this.stage.animateIn(el, i, () => {
            if (++completedNum == this.chapter.Objects.length) {
              // console.log('All object animations done');
              this.store.setExperienceState(EXP_STATES.SCENE);

              if (window.DJS_SKIP_INTRO && window.START_OBJECT && this.chapter.Objects) {
                const obj = this.chapter.Objects.find(o => o.Identifier === window.START_OBJECT);
                delete window.START_OBJECT;
                if (!obj) return;
                this.objectClickHandler(obj);
              }
            }
          });
        });

        // document.body.classList.remove( 'intro-playing' );

        // this.videoEl.style.visibility = 'hidden';
        this.videoPlayer.fadeOut();

        !this.videoPlayerProxy || ( this.videoPlayerProxy.isPlaying = true );
        this.stage.resumeVideoElements();

        this.startPotentialStageSubtitles();
      }

    },

    startPotentialStageSubtitles() {
      const videoObject = this.chapter.Objects.find(o => o.Type === 'Decoration' && o.Video && o.Settings && o.Settings.subtitles);
      if ( ! videoObject ) return;
      const videoElement = document.getElementById( 'so_video_' + safeString(videoObject.Identifier) );
      if ( ! videoElement ) return;

      this.videoPlayerProxy = new VideoPlayerProxy( videoElement );
      this.videoPlayerProxy.isPlaying = true;
      // console.log('startPotentialStageSubtitles', safeString(videoObject.Identifier), this.videoPlayerProxy);

      this.subtitlePlayer.setStageVideoPlayer(this.videoPlayerProxy);
      this.subtitlePlayer.setStageSubtitles(videoObject.Settings.subtitles);
      this.subtitlePlayer.start();
    },  



    exitDetail() {
      console.log('exitDetail');
      if (this.detail) {
        this.detail.exit();
      }
      this.store.setExperienceState(EXP_STATES.DETAIL_TO_SCENE);
      // this.stage.$el.style.transform = ``;

      setTimeout(() => {
        delete this.selectedObject;
        print("");
        // Resume videos
        !this.videoPlayerProxy || ( this.videoPlayerProxy.isPlaying = true );
        this.stage.resumeVideoElements();
        this.store.setExperienceState(EXP_STATES.SCENE);
        this.detail.postExit();
      }, SCENE_TRANSITION_DURATION_OUT);
    },

    // durationOnPlayHandler(duration) {
    durationOnPlayHandler() {
      // console.log('durationOnPlayHandler', duration, Math.round((duration || 10) * 1000));
      // document.getElementById( 'stage' ).classList.add( 'active' );
      // anime( {
      //   targets: document.getElementById( 'stage' ),
      //   scale: '1',
      //   duration: 10000,
      //   easing: 'easeOutSine',
      // } );
    },

    skipVideo() {
      this.skipVideoBtn.style.display = 'none';
      this.videoPlayer.stop();
      this.videoEndHandler();

      this.$gtag.event( 'click', { target: 'SkipButton', name: 'SkipIntroVideo', context: 'DeJoodseStraatView' } );
    },

    linkHandler(linkTargetId) {
      console.log('DeJoodseStraatView', linkTargetId);

      this.exitDetail();

      setTimeout(() => {
        const obj = this.chapter.Objects.find(o => o.Identifier === linkTargetId);
        if (!obj) return;
        this.objectClickHandler(obj);
      }, SCENE_TRANSITION_DURATION_OUT + 500);
    },

    stageObjectVideoEnded(soData) {
      console.log('stageObjectVideoEnded', soData);
      if (soData.Settings && soData.Settings.videoEndsExperience && soData.Settings.videoEndsExperience === true) {
        this.endExperience();
      }
    },

    endExperience() {
      console.log('endExperience');
      if (this.stage && this.stage.collapseObjects) this.stage.collapseObjects();
      if (this.stagebackground && this.stagebackground.collapseObjects) this.stagebackground.collapseObjects();
      if (this.wedgesplate && this.wedgesplate.growDark) this.wedgesplate.growDark();
      this.store.setExperienceState( EXP_STATES.ENDED );
    },

  },

  mounted() {
    // console.log('DeJoodseStraat|mounted');

    const timer = setInterval(() => {
      if (this.store.hasContent) {
        clearInterval(timer);

        this.startChapter();
      }
    }, 100);

    this.$gtag.event( 'view', { name: 'DeJoodseStraatView' } );

  },


  watch: {
    // experienceState( newValue, oldValue ) {
    experienceState(newValue) {

      // console.log( 'DeJoodseStraat|watch2|experienceState', oldValue, '-->', newValue)

      switch (newValue) {

        case 'scene':
          this.stage.setTitle();
          break;

        case 'intro':
          this.skipVideoBtn.style.display = 'block';
          break;

        case 'intro_to_scene':
          this.stage.setTitle();
          this.skipVideoBtn.style.display = 'none';
          break;
      }
    }
  },

};
</script>

<template>
  <BackPlate ref="backplate" :classes="experienceClasses('backplate')" :bgimg="chapterBackground">
  </BackPlate>

  <StageBackground ref="stagebackground" :chapter="chapter" :classes="experienceClasses('stagebackground')">
  </StageBackground>

  <WedgesPlate ref="wedgesplate" :chapter="chapter">
  </WedgesPlate>

  <StageRoot ref="stage" :chapter="chapter" :classes="experienceClasses('stage', ['router-view'])"
    @objectClick="objectClickHandler" @stageObjectVideoEnded="stageObjectVideoEnded">
  </StageRoot>

  <ColorPlate ref="colorPlate" cls="color-plate" :style="colorPlateStyle">
  </ColorPlate>

  <DetailView ref="detail" @exit-detail="exitDetail" @link-click="linkHandler"
    :selectedObject="selectedObject || { 'Detail': { 'Body': '', 'Title': '' } }">
  </DetailView>

  <VideoPlayer v-if="hasVideo" :videofiles="chapter.IntroVideo"
    :scale="chapter.Settings ? chapter.Settings.introVideoScale || 1 : 1" ref="videoPlayer" 
    @duration-on-play="durationOnPlayHandler" @ended="videoEndHandler">
  </VideoPlayer>

  <div ref="skipVideoBtn" class="skip-video" @click="skipVideo">Skip</div>

  <Transition name="endtrans">
    <EndModal v-if="isExperienceEnded" :data="store.app_content.settings.Endscreen ?? null" />
  </Transition>

  <SubtitlePlayer v-if="hasSubtitles" :videoPlayer="videoPlayer || {}" :subtitles="chapter.Settings.introVideoSubtitles"
    ref="subtitlePlayer" id="subtitleEl" :style="subtitleStyle">
  </SubtitlePlayer>

  <VideoEventPlayer v-if="hasEvents" :videoPlayer="videoPlayer || {}" :events="chapter.Settings.introVideoEvents"
    ref="eventsPlayer">
  </VideoEventPlayer>

  <ProgressBar ref="progressBar" :activeChapterNumber="activeChapterNumber" @click-chapter="chapterClickHandler">
  </ProgressBar>
</template>