<script>
import { detect } from 'detect-browser';
import { useAppStore } from '@/store';

export default {
    name: 'SubtitlePlayer',

    props: {
        subtitles: {
            type: Object,
            // required: true,
            default: () => { },
        },

        videoPlayer: {
            type: Object,
            // required: true,
            default: () => { },
        },
    },

    data() {
        return {
            el: null,
            subtitleEl: null,
            browser: { name: 'unknown', version: 0, os: 'unknown' },
            tickInterval: null,
            stageVideoPlayer: null,
            stageSubtitles: null,
            // subtitleTimeout: null,
            // subtitleTimeoutTime: 3800,
            // indicesDoneList: [],
        };
    },

    setup() {
        const store = useAppStore();
        return { store };
    },

    mounted() {
        this.browser = detect() || { name: 'unknown', version: 0, os: 'unknown' };
        this.el = document.getElementById( 'subtitles' );
        this.subtitleEl = document.getElementById( 'subtitle' );

        // this.tickInterval = setInterval( this.tick, 100 );
    },

    computed: {
        wrapperClasses() {
            const cls = ['subtitles'];
            if (this.stageSubtitles) cls.push('subs-stage');
            return cls.join(' ');
        },

        whichVideoPlayer() {
            return this.stageVideoPlayer || this.videoPlayer;
        },

        whichSubtitles() {
            return this.stageSubtitles || this.subtitles;
        },
    },

    methods: {
        setStageVideoPlayer(stageVideoPlayer) {
            this.stageVideoPlayer = stageVideoPlayer;
        },
        
        setStageSubtitles(stageSubtitles) {
            this.stageSubtitles = stageSubtitles;
        },
        
        unsetStageVideoPlayer() {
            this.stageVideoPlayer = null;
        },
        
        unsetStageSubtitles() {
            this.stageSubtitles = null;
        },

        tick() {
            // console.log(this.whichVideoPlayer.getIsPlaying());
            if ( !this.whichVideoPlayer.el || !this.whichVideoPlayer.getIsPlaying() ) return;

            const videoTime = this.whichVideoPlayer.el.currentTime;
            
            const subtitle = this.whichSubtitles.find( (x) => {
                // console.log(x.start_time, x.end_time);
                return videoTime >= x.start_time && videoTime < x.end_time;
            } );
            // console.log(videoTime, subtitle);

            // Show subtitle if found and not already shown
            if ( subtitle && this.subtitleEl.innerHTML != subtitle.text) {
                this.subtitleEl.innerHTML = subtitle.text;
                this.subtitleEl.classList.remove( 'top', 'bottom' );
                // this.subtitleEl.classList.add( 'fade-in', subtitle.position || 'bottom' );
                this.subtitleEl.classList.add( 'fade-in', subtitle.position || 'top' );

            // Clear subtitle if no subtitle and not already cleared
            } else if (!subtitle && this.subtitleEl.classList.contains('fade-in')) {
                this.subtitleEl.classList.remove( 'fade-in' );
                // this.subtitleEl.innerHTML = "";
            }
        },

        start() {
            // console.log('Subs start')
            this.el = document.getElementById( 'subtitles' );
            clearInterval( this.tickInterval );
            this.tickInterval = setInterval( this.tick, 100 );
        },

        // showSubtitle( text ) {
        //     !this.subtitleTimeout|| clearTimeout( this.subtitleTimeout );

        //     this.subtitleEl.innerHTML = text;
        //     this.subtitleEl.classList.add( 'fade-in' );

        //     this.subtitleTimeout = setTimeout( () => {
        //         this.subtitleEl.innerHTML = '';
        //         this.subtitleEl.classList.remove('fade-in');
        //     }, this.subtitleTimeoutTime );
        // },
        
        // clearIndicesDoneList() {
        //     this.indicesDoneList = [];
        // },

        reset() {
            // console.log('Subs reset')
            clearInterval( this.tickInterval );
            // clearTimeout( this.subtitleTimeout );
            
            // this.clearIndicesDoneList();
            
            this.subtitleEl.classList.remove('fade-in');

            if (this.el) {
                this.el.classList.remove( 'stage' );
            }
            
            // setTimeout( () => {
            //     this.subtitleEl.innerHTML = '';
            // }, 510);
        },
    },
};
</script>

<template>
    <div id="subtitles" :class="wrapperClasses">
        <div id="subtitle" class="subtitle"></div>
    </div>
</template>