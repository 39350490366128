<script>
import { useAppStore } from '@/store';
// import { storeToRefs } from 'pinia';
import { parseContent } from '@/util/contentparser';

import PageFooter from '@/components/PageFooter.vue';

export default {
  name: "InfopageView",

  components: {
    PageFooter,
  },

  data() {
    return {
      pageData: {Title:"", Body:""},
    };
  },

  setup() {
    const store = useAppStore();

    return {
      store,
      parseContent,
    };
  },

  computed: {
  },

  created() {
    this.store.fetchInfopage(this.$route.params.slug, (success, response) => {
      // console.log( success, response.data[ 0 ].attributes )
      if (success) this.pageData = response.data[0].attributes;
    });
  },

  mounted() {
    this.$gtag.event( 'view', { name: 'InfoPageView', title: this.pageData.Title ?? "-" } );

  },
};
</script>
<template>
  <div class="router-view">
    <h1 class="page-title">{{ pageData.Title }}</h1>
    <section v-html="parseContent( pageData.Body, store )"></section>
    <PageFooter />
  </div>
</template>