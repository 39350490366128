<script>
import { useAppStore } from '@/store';
import { ref } from 'vue';

export default {
    name: 'ContentBottomLeft',

    props: {
        selectedObject: {
            type: Object,
            required: true,
        },
    },

    components: {
    },

    setup() {
        const store = useAppStore();
        const vinylRecord = ref( null );

        return {
            store,
            vinylRecord
        };
    },

    computed: {
        title() {
            return this.selectedObject.Detail && this.selectedObject.Detail.Title
                ? this.selectedObject.Detail.Title
                : (this.selectedObject.Title || '');
        },
    },

    methods: {
        exit() {
            console.log('ContentBottomLeft::exit');
        },
    },
};

</script>
<template>
    <div class="detail-content">
        <div class="shapes ascale">
            <img class="tmpl-content-left2-wig mbm-multiply" src="@/assets/contentwig-l2.svg">
            <img class="tmpl-content-left2-wig mbm-opacity" src="@/assets/contentwig-l2.svg">
        </div>
        <div class="content-block">
            <h1>{{ title }}</h1>
            <p v-html="selectedObject.Detail.BodyContent"></p>
        </div>
    </div>  
</template>