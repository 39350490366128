import { defineStore } from 'pinia';
import LZString from 'lz-string';
import axios from "axios";

export const EXP_STATES = {
    NONE: 'none',
    SETUP: 'setup',
    PAUSED: 'paused',
    INTRO: 'intro',
    INTRO_TO_SCENE: 'intro_to_scene',
    SCENE: 'scene',
    SCENE_TO_DETAIL: 'scene_to_detail',
    SCENE_TO_INTRO: 'scene_to_intro',
    DETAIL: 'detail',
    DETAIL_TO_SCENE: 'detail_to_scene',
    DETAIL_TO_INTRO: 'detail_to_intro',
    ENDED: 'ended',
};

export const useAppStore = defineStore( 'app', {

    state: () => {
        // const empty = { "id": null, "Title": "", "Body": "", "Settings": null };
        return {
            strapi_url: "",
            app_content: null,
            active_chapter_number: 0,
            exp_state: EXP_STATES.NONE,
        };
    },

    getters: {
        hasContent: ( state ) => state.app_content !== null,
        strapiUrl: ( state ) => state.strapi_url,
        activeChapterNumber: ( state ) => state.active_chapter_number,
        experienceState: ( state ) => state.exp_state,
    },

    actions: {
        setStrapiUrl( strapi_url ) {
            this.strapi_url = strapi_url;
            console.log( `Strapi url set to ${strapi_url}` );
        },

        setExperienceState( state ) {
            this.exp_state = state;
        },

        getExperienceState() {
            return this.exp_state;
        },

        setActiveChapterNumber( chapterNumber ) {
            this.active_chapter_number = parseInt( chapterNumber );
        },

        setChapterObjectVisited( chapterObject ) {
            const chapter = this.getChapterByNumber( this.activeChapterNumber );
            if ( chapter )
            {
                const object = chapter.Objects.find( ( object ) => object.id === chapterObject.id && object.Identifier === chapterObject.Identifier );
                if ( object )
                {
                    // console.log(object)
                    object.hasVisited = true;
                }
            }
        },

        getChapterByNumber( chapterNumber ) {
            // console.log( "getChapterByNumber", chapterNumber)
            const key = Object.keys( this.app_content.chapters ).find( ( key ) => this.app_content.chapters[ key ].Number == chapterNumber );
            if ( key ) return this.app_content.chapters[ key ];
            return false;
        },

        // getAudioFileById( id ) {
        //     if ( !this.app_content || !this.app_content.audioFiles ) return false;
        //     const audioFile = this.app_content.audioFiles.find( ( audioFile ) => audioFile.id === id );
        //     return audioFile || false;
        // },

        async fetchContent(callback) {
            let result = null;

            try
            {
                if (window.localStorage) {

                    // Clear cache on different version 
                    const djs_cur_ver = require( '../../package.json' ).version ?? "none";
                    const djs_last_ver = localStorage.getItem( 'djs_last_ver' ) ?? "none";
                    if ( djs_last_ver != djs_cur_ver ) {
                        console.log( 'Version changed, clearing cache' );
                        localStorage.removeItem( 'djs_cache' );
                        localStorage.setItem( 'djs_last_ver', djs_cur_ver );
                    }

                    const djs_cache = localStorage.getItem( 'djs_cache' );
                    if ( djs_cache ) {
                        const cache_time = process.env.VUE_APP_STRAPI_CACHING_TIME || 30;
                        console.log( `Cache time: ${cache_time} sec`);
                        const djs_cache_time = localStorage.getItem( 'djs_cache_time' );
                        const now = new Date().getTime();
                        const diff = djs_cache_time ? now - djs_cache_time : 0;
                        const isStale = diff > 1000 * cache_time; 
                        if (!isStale) {
                            result = JSON.parse( LZString.decompress(djs_cache) );
                            console.log( "Using cached data" );
                        }
                    }
                }
            } catch (error) {
                console.error(error);
            }

            try
            {
                if ( !result ) {
                    const response = await axios.get( this.strapi_url + "/api/content" );
                    if ( window.localStorage ) {
                        localStorage.setItem( 'djs_cache', LZString.compress(JSON.stringify( response.data )) );
                        localStorage.setItem( 'djs_cache_time', new Date().getTime() );
                    }
                    console.log( "Using fresh data" );
                    result = response.data;
                }
                
                this.app_content = result;

                // Parse Home
                if (this.app_content && this.app_content.pages && this.app_content.pages.home && this.app_content.pages.home.Settings && this.app_content.pages.home.Settings.landingVideoSubtitles) {
                    this.app_content.pages.home.Settings.landingVideoSubtitles = this.parseSubtitles( this.app_content.pages.home.Settings.landingVideoSubtitles );
                }

                // Parse Chapters
                for ( const chapter of Object.values( this.app_content.chapters ) )
                {
                    if ( chapter.Objects ) 
                        chapter.Objects = this.parseObjects( chapter, this );

                    if ( chapter.Settings && chapter.Settings.introVideoSubtitles ) 
                        chapter.Settings.introVideoSubtitles = this.parseSubtitles( chapter.Settings.introVideoSubtitles )

                    // console.log( chapter.Settings.introVideoSubtitles )
                }
                this.setActiveChapterNumber( window.START_CHAPTER );

                if (callback) {
                    callback(true, this.app_content);
                }

                // const recursiveLog = ( obj, name = '', level = 0) => {
                //     if (typeof obj === 'object') {
                //         for (const [key, value] of Object.entries(obj)) {
                //             if (key === 'Settings' && value) {
                //                 console.log(`${' '.repeat(level)}${name}: ${key}: ${JSON.stringify(value)}`);
                //                 continue;
                //             }
                //             if (value) recursiveLog(value, key, level + 1);
                //         }
                //     }
                // }
                // recursiveLog(this.app_content.chapters, 'chapters');
            }
            catch ( error )
            {
                this.app_content = false;
                // alert( error );
                console.error( error );
                
                if ( callback )
                {
                    callback( false, this.app_content );
                }

                throw new Error( 'Error loading data from Strapi' );
            }
        },

        async fetchInfopage( slug, callback ) {
            console.log( "fetchInfopage", slug );
            try
            {
                const result = await axios.get( `${this.strapi_url}/api/infopages?filters[Slug][$eq]=${slug}` );
                // console.log( "STRAPI", result.data );
                // this.app_content = result.data;
                if (callback) {
                    callback(true, result.data);
                }
            }
            catch ( error )
            {
                console.error( error );

                if ( callback )
                {
                    callback( false, error );
                }
            }
        },

        parseObjects: ( chapter, thisProxy ) => {
            if ( !chapter.Objects ) return [];

            // console.log( thisProxy );

            return chapter.Objects.map( ( object ) => {
                if ( object.Mandatory )
                {
                    object.hasVisited = false;
                }
                if (object.Settings && object.Settings.subtitles) {
                    object.Settings.subtitles = thisProxy.parseSubtitles( object.Settings.subtitles, 'bottom' );
                }
                return object;
            } ).filter( ( object ) => object.Identifier && object.Type && object.Xpos && object.Ypos );
        },

        parseSubtitles: ( subtitles, forcedPosition ) => {
            if ( !subtitles ) return [];
            
            const subTitleDuration = 4;

            return subtitles.sort( ( a, b ) => a.start - b.start ).map( ( subtitle, index ) => {
                const nextStartTime = subtitles[ index + 1 ] 
                    ? subtitles[ index + 1 ].start_time 
                    : subtitle.end_time 
                        ? subtitle.end_time + 0.1  
                        : subtitle.start_time + subTitleDuration;
                
                if (!subtitle.end_time || subtitle.end_time > nextStartTime) {
                    subtitle.end_time = nextStartTime - 0.1;
                }

                if ( forcedPosition && forcedPosition !== '') {
                    subtitle.position = forcedPosition;
                }

                return subtitle;
            });
        }
    },
} );