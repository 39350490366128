<script>
import { ref } from 'vue';
import { detect } from 'detect-browser';
import { useAppStore } from '@/store';
import { EXP_STATES } from '@/store';
import { getDomainFromUrl, getYoutubeIdFromUrl } from '@/util/urlutils';

export default {
    name: 'VideoElement',

    props: {
        data: {
            type: Object,
            required: false,
        },
    },

    data() {
        return {
            localVideoEl: null,
            player: null,
            localVideoIsPlaying: false,
            browser: { name: 'unknown', version: 0, os: 'unknown' },
        };
    },

    setup() {
        const store = useAppStore();
        const embeddedPlayer = ref( null );
        const videoElement = ref( null );

        return {
            embeddedPlayer,
            videoElement,
            store,
        };
    },

    computed: {
        localVideoPlayingClass() {
            return this.localVideoIsPlaying ? 'playing' : ( this.store.getExperienceState() == EXP_STATES.PAUSED ? 'paused' : '' );
        },

        playerClass() {
            const cls = ['video-element'];
            return cls.join(' ');
        },

        isYoutube() {
            return this.data && !this.data.LocalVideo && this.data.VideoUrl && this.data.VideoUrl.indexOf('youtube') > -1;
        },

        isLocal() {
            return this.data && this.data.LocalVideo;
        },

        // hasVideo() {
        //     return this.data && this.data.VideoUrl && this.data.VideoUrl != "";
        // },

        // hasFrame() {
        //     return this.data && this.data.VideoFrame && this.data.VideoFrame != "";
        // },

        videoWidth() {
            return this.data && this.data.VideoWidth ? this.data.VideoWidth : 400;
        },

        videoHeight() {
            return this.data && this.data.VideoHeight ? this.data.VideoHeight : 300;
        },

        videoStyle() {
            const styles = [];
            styles.push( `width: ${ this.data.VideoWidth / 19.20 }vw` );
            styles.push( `height: ${ this.data.VideoHeight / 19.20 }vw` );
            
            const transforms = [];
            if (this.data.VideoOffsetX) {
                transforms.push( `translateX(${ this.data.VideoOffsetX / this.data.VideoWidth * 100 }%)` );
            }
            if (this.data.VideoOffsetY) {
                transforms.push( `translateY(${ this.data.VideoOffsetY / this.data.VideoHeight * 100 }%)` );
            }
            if (this.data.Settings && this.data.Settings.videoElementScale ) {
                transforms.push( `scale(${ this.data.Settings.videoElementScale })` );
            }
            styles.push( `transform: ${ transforms.join(' ') }` );
            
            return styles.join( ';' );
        },
        
        frameStyle() {
            const styles = [];
            styles.push( `width: ${this.data.VideoFrame.width / 19.20}vw` );
            styles.push( `height: ${this.data.VideoFrame.height / 19.20}vw` );
            return styles.join( ';' );
        },

        frameSource() {
            return this.store.strapiUrl + this.data.VideoFrame.url;
        },

        textStyle() {
            if (!this.data.TextWidth) return "";
            
            const styles = [];

            styles.push( `width: ${ this.data.TextWidth / 19.20 }vw` );

            return styles.join( ';' );
        },
    },

    methods: {
        postEnter() {
            // console.log('VideoElement::postEnter');
            
            if ( this.isYoutube ) {
                this.initYoutubePlayer(() => {
                    const youtubeId = getYoutubeIdFromUrl( this.data.VideoUrl );
                    if ( youtubeId ) {
                        this.player.loadVideoById( youtubeId );
                    }
                });
            } else if ( this.isLocal ) {
                this.localVideoEl.play();
            }
        },

        initYoutubePlayer(callback) {
            console.log('VideoElement::initYoutube');
            if ( !window.YT ) return;
            this.player = new window.YT.Player( `player-${ this.data.id }`, {
                playerVars: {
                    'autoplay': 1,
                    'disablekb': 1,
                    'controls': 1,
                    'playsinline': 1,
                    'iv_load_policy': 3,
                    'cc_load_policy': 0,
                    'modestbranding': 1,
                    'enablejsapi': 1,
                    'widget_referrer': window.location.origin,
                    'origin': getDomainFromUrl(window.location.origin),
                    'showinfo': 0,
                    'rel': 0,
                    'hl': 'nl',
                },
                events: {
                    'onReady': callback ? callback : () => console.warn('videoelement has no onReady callback'),
                }
            } );
        },

        exit() {
            console.log('VideoElement::exit');
            if ( this.isYoutube && this.player ) {
                this.player.stopVideo();
                setTimeout(() => this.player.destroy(), 100);
                setTimeout(() => this.player = null, 200);
            } else if ( this.isLocal && this.localVideoEl ) {
                this.localVideoEl.pause();
                this.localVideoEl.currentTime = 0;
            }
        },

        playVideo() {
            if (this.isYoutube && this.player) {
                this.player.playVideo();
            } else if (this.isLocal && this.localVideoEl ) {
                this.localVideoEl.play();
            }
        },

        pauseVideo() {
            if (this.isYoutube && this.player) {
                this.player.pauseVideo();
            } else if (this.isLocal && this.localVideoEl ) {
                this.localVideoEl.pause();
            }
        },

        resumeVideo() {
            if (this.isYoutube && this.player) {
                this.player.playVideo();
            } else if (this.isLocal && this.localVideoEl ) {
                this.localVideoEl.play();
            }
        },

        stopVideo() {
            if (this.isYoutube && this.player) {
                this.player.stopVideo();
            } else if (this.isLocal && this.localVideoEl ) {
                this.localVideoEl.pause();
                this.localVideoEl.currentTime = 0;
            }
        },

        localVideoOnPlaying() {
            console.log('localVideoOnPlaying');
            this.localVideoIsPlaying = true;
        },

        localVideoOnPause() {
            console.log('localVideoOnPause');
            this.localVideoIsPlaying = false;
        },

        localVideoOnEnded() {
            console.log('localVideoOnEnded');
            this.localVideoIsPlaying = false;
        },
    },

    mounted() {
        this.browser = detect() || { name: 'unknown', version: 0, os: 'unknown' };

        this.localVideoEl = document.getElementById( 'localVideoPlayer' );
        if (this.localVideoEl) {
            this.localVideoEl.addEventListener( 'playing', this.localVideoOnPlaying );
            this.localVideoEl.addEventListener( 'pause', this.localVideoOnPause );
            this.localVideoEl.addEventListener( 'ended', this.localVideoOnEnded );
        }
    },
};
</script>

<template>
    <div ref="videoElement" :class="playerClass">

        <div class="videoPlayerBackdrop" :style="videoStyle"></div>
        
        <div v-if="isYoutube" class="video-element-video" :style="videoStyle">
            <div ref="embeddedPlayer" :id="`player-${data.id}`"></div>
        </div>

        <video v-if="isLocal" id="localVideoPlayer" class="videoPlayer" controls preload="auto" playsinline :style="videoStyle">
            <source v-if="browser.name == 'safari' || browser.name == 'ios'"
                :src="`${store.strapiUrl}${data.LocalVideo.VideoHEVC.url}`" type="video/mp4" codecs="hvc1">
            <source v-else-if="browser.name != 'unknown'" :src="`${store.strapiUrl}${data.LocalVideo.VideoVP9.url}`"
                type="video/webm">
        </video>

        <img class="video-element-frame" :src="frameSource" :style="frameStyle">
        <div v-if="data.Text" class="video-txt" :style="textStyle">{{ data.Text }}</div>
    </div>
</template>