<script>
import { useAppStore } from '@/store';

export default {
    name: 'PersonHorizontal',

    props: {
        selectedObject: {
            type: Object,
            required: true,
        },
    },

    computed: {
        title() {
            return this.selectedObject.Detail && this.selectedObject.Detail.Title
                ? this.selectedObject.Detail.Title
                : (this.selectedObject.Title || '');
        },

        contentBlockStyle() {
            const styles = [];
            if (this.selectedObject.Detail && this.selectedObject.Detail.Settings && this.selectedObject.Detail.Settings.articleWidth) {
                styles.push(`width: ${this.selectedObject.Detail.Settings.articleWidth}`);
            }
            return styles.join(';');
        },
    },
    
    setup() {
        const store = useAppStore();

        return {
            store
        };
    },

    mounted() {
        console.log( "PersonHorizontal|mounted" );
    },
};

</script>
<template>
    <div class="detail-content">
        <div id="shapesContainer" class="shapes">
            <img class="tmpl-content-ellipse" src="@/assets/ellipse.svg">
        </div>

        <!--<img class="detail-image" :src="store.strapiUrl + selectedObject.Detail.Image.url" alt="detail image">-->
        <main>
            <div class="content-block" :style="contentBlockStyle">
                <h2>{{ title }}</h2>
                <article v-html="selectedObject.Detail.BodyContent"></article>
            </div>
        </main>

    </div>  
</template>