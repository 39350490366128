const isCellular = 'connection' in navigator && navigator.connection.type == 'cellular';

const isIOS = /iPad|iPhone|iPod/.test( navigator.userAgent ) && !window.MSStream;

const isSafari = !!navigator.userAgent.match( /Version\/[\d.]+.*Safari/ );

const isPhoneType = navigator.userAgent.match( /iPhone/i )
    || navigator.userAgent.match( /Lumia/i )
    || navigator.userAgent.match( /RM-1127/i )
    || navigator.userAgent.match( /RM-1152/i )
    || navigator.userAgent.match( /iPhone/i )
    || navigator.userAgent.match( /M2102J20SG/i )
    || navigator.userAgent.match( /M2101K6G/i )
    || navigator.userAgent.match( /MAR-LX1A/i )
    || navigator.userAgent.match( /VOG-L29/i )
    || navigator.userAgent.match( /Redmi\sNote/i )
    || navigator.userAgent.match( /Moto\sg/i )
    || navigator.userAgent.match( /Pixel\s8/i )
    || navigator.userAgent.match( /Pixel\s7/i )
    || navigator.userAgent.match( /Pixel\s6/i )
    || navigator.userAgent.match( /SM-G998/i )
    || navigator.userAgent.match( /SM-G991/i )
    || navigator.userAgent.match( /SM-G973/i )
    || navigator.userAgent.match( /SM-G955/i )
    || navigator.userAgent.match( /SM-S901/i )
    || navigator.userAgent.match( /SM-S908/i )
    || navigator.userAgent.match( /SM-F900U/i )
    || navigator.userAgent.match( /SM-A536/i )
    || navigator.userAgent.match( /SM-A515/i )
    || navigator.userAgent.match( /G981B/i );

export {
    isCellular,
    isIOS,
    isSafari,
    isPhoneType
};