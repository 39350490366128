export default {

    methods: {

        $test() {
            return "TEST";
        },

        $objValueOrDefault(obj, prop, defaultValue = '') {
            if ("undefined" === typeof obj) return defaultValue;
            if ("undefined" === typeof obj[prop]) return defaultValue;
            return obj[prop];
        },

        $addDebug() {
            let urlParams = new URLSearchParams( window.location.search );
            if ( urlParams.has( 'debug' ) )
            {
                window.DJS_IS_DEBUG = true;
                if (!document.body.classList.contains( 'is-debug' )) document.body.classList.add( 'is-debug' );
            } else
            {
                window.DJS_IS_DEBUG = false;
                if (document.body.classList.contains( 'is-debug' )) document.body.classList.remove( 'is-debug' );
            }
        }

    },

    computed: {

        $testName() {
            return "TEST";
        },

    },

};