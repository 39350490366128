<script>
import { ref } from 'vue';

import anime from 'animejs/lib/anime.es.js';

import { safeString } from '@/util/stringutils.js';

import StageObject from "@/components/StageObject.vue";
import ChapterTitle from "@/components/ChapterTitle.vue";

const INITIAL_DELAY = 0;
const STAGGER_DELAY = 160;
const ICON_IN_DURATION = 1200;
const PERSON_TITLE_IN_DURATION = 900;
const OBJ_IN_FADE_DURATION = 500;
const OBJ_IN_FLIP_DURATION = 2300;
const OBJ_IN_FLIP_AMPLITUDE = 1; // 1 - 10	Controls the overshoot of the curve. The larger this number, the more overshoot there is.
const OBJ_IN_FLIP_PERIOD = 0.3; // 0.1 - 2	Controls how many times the curve goes back and forth. The smaller this number, the more times the curtain goes back and forth.
const OBJ_IN_ZOOM_DURATION = 900;
const OBJ_IN_BOUNCE_DURATION = 1200;
const OBJ_Z_SPACING = 30;

export default {
    name: 'StageRoot',

    props: {
        chapter: {
            type: Object,
            // required: true
        },
        classes: {
            type: String,
            default: ""
        }
    },

    components: {
        ChapterTitle,
        StageObject
    },

    data() {
        return {
            titleProxy: "",
            titleStyle: "",
            periodProxy: "",
            periodStyle: "",
        };
    },

    emits: [
        'objectClick',
        'stageObjectVideoEnded',
    ],

    setup() {
        const title = ref( null );
        const objects = ref( null );

        return {
            title,
            objects,
        };
    },

    computed: {
        // title() {
        //     return this.chapter ? this.chapter.Title : "";
        // },

        // titleStyle() {
        //     if ( this.chapter && this.chapter.Settings && this.chapter.Settings.titleColor && this.chapter.Settings.titleColor !== "" ) {
        //         return `color: ${this.chapter.Settings.titleColor}`;
        //     }

        //     return "color: var(--col-title)";
        // },

        periodInfo() {
            if ( !this.chapter || !this.chapter.PeriodInfo ) return '';
            return this.chapter.PeriodInfo;
        },

        chapterObjects() {
            if ( !this.chapter || !this.chapter.Objects ) return [];
            // console.log(typeof this.chapter.Objects, this.chapter.Objects)
            return this.chapter.Objects.filter( ( o ) => !o.Settings || !o.Settings.stageBackground || o.Settings.stageBackground !== true );
        }
    },

    methods: {

        setTitle() {
            this.titleProxy = this.chapter && this.chapter.Title ? this.chapter.Title : "";

            this.periodProxy = this.periodText();

            if ( !this.title ) return;

            if ( this.chapter && this.chapter.Settings && this.chapter.Settings.titleColor && this.chapter.Settings.titleColor !== "" )
            {
                this.titleStyle = `color: ${ this.chapter.Settings.titleColor }`;
            } else
            {
                this.titleStyle = "color: var(--col-title)";
            }

            if ( this.chapter && this.chapter.Settings && this.chapter.Settings.periodColor && this.chapter.Settings.periodColor !== "" )
            {
                this.periodStyle = `color: ${ this.chapter.Settings.periodColor }`;
            } else
            {
                this.periodStyle = "color: var(--col-period)";
            }
        },

        periodText() {
            if ( !this.chapter || ( !this.chapter.PeriodFrom && !this.chapter.PeriodTo ) ) return '';
            if ( this.chapter.PeriodFrom && !this.chapter.PeriodTo )
            {
                return `${ this.chapter.PeriodFrom }`;
            } else if ( !this.chapter.PeriodFrom && this.chapter.PeriodTo )
            {
                return `${ this.chapter.PeriodTo }`;
            } else
            {
                return `${ this.chapter.PeriodFrom } - ${ this.chapter.PeriodTo }`;
            }
        },

        objKey( obj ) {
            // console.log(obj);
            return obj.id || new Date().getTime() + Math.random();
        },

        objId( obj ) {
            // console.log(obj);
            return 'so-' + obj.id + '-' + safeString( obj.Identifier );
        },

        calcZOffset( i ) {
            // return i * 1;
            // return ( this.chapter.Objects.length || 0 ) + i * OBJ_Z_SPACING;
            return ( this.chapter.Objects.length || 0 ) * 0.5 * -OBJ_Z_SPACING + i * OBJ_Z_SPACING;
        },

        animateIn( el, i, callback ) {
            // console.log('animateIn', el, i);
            el.style.display = 'block';

            const complete = () => {
                callback.call( this );
                const iconEl = el.querySelector( '.icon' );
                if ( iconEl )
                {
                    iconEl.style.display = 'block';
                    iconEl.style.transform = 'scale(0)';
                    anime( {
                        targets: iconEl,
                        scale: '1',
                        duration: ICON_IN_DURATION,
                        easing: 'easeOutElastic(1, .4)',
                    } );
                }
                const personTitleEl = el.querySelector( '.person-title' );
                if ( personTitleEl )
                {
                    personTitleEl.style.display = 'block';
                    personTitleEl.style.opacity = '0';
                    // personTitleEl.style.transform = 'scale(0)';
                    anime( {
                        targets: personTitleEl,
                        opacity: '1',
                        duration: PERSON_TITLE_IN_DURATION,
                        easing: 'easeOutQuart(1, .4)',
                    } );
                }
            };

            switch ( el.dataset.anm )
            {
                case 'Fade':
                    anime( {
                        targets: el,
                        opacity: '1',
                        duration: OBJ_IN_FADE_DURATION,
                        easing: 'linear',
                        delay: INITIAL_DELAY + i * STAGGER_DELAY,
                        complete
                    } );
                    break;

                case 'Flip':
                    anime( {
                        targets: el,
                        rotateX: '0deg',
                        scaleY: '1',
                        // translateZ: `${i * 40}px`,
                        duration: OBJ_IN_FLIP_DURATION,
                        easing: `easeOutElastic(${ OBJ_IN_FLIP_AMPLITUDE }, ${ OBJ_IN_FLIP_PERIOD })`,
                        delay: INITIAL_DELAY + i * STAGGER_DELAY,
                        complete
                    } );
                    break;

                case 'Zoom':
                    anime( {
                        targets: el,
                        scale: '1',
                        rotate: '0',
                        duration: OBJ_IN_ZOOM_DURATION,
                        easing: 'easeOutSine',
                        delay: i * STAGGER_DELAY,
                        complete
                    } );
                    break;

                case 'Bounce':
                    anime( {
                        targets: el,
                        scale: '1',
                        rotate: '0',
                        duration: OBJ_IN_BOUNCE_DURATION,
                        easing: 'easeOutBack',
                        delay: i * STAGGER_DELAY,
                        complete
                    } );
                    break;
            }
        },

        onBeforeEnter( el ) {
            el.style.display = 'none';

            switch ( el.dataset.anm )
            {
                case 'Fade':
                    el.style.opacity = `0`;
                    el.style.transform = `translate( -50%, -100%) translatez(${ this.calcZOffset( el.dataset.index ) }px) scale(1)`;
                    break;

                case 'Flip':
                    el.style.transform = `translate( -50%, -100%) translateZ(${ this.calcZOffset( el.dataset.index ) }px) rotateX( -20deg ) scaleY(0)`;
                    // el.style.transform = `translate( -50%, -100%) translateZ(${ el.dataset.index * 30 }px) rotateX( -10deg ) scaleY(0.3)`;
                    break;

                case 'Zoom':
                case 'Bounce':
                    el.style.transformOrigin = 'center 90%';
                    el.style.transform = `translate( -50%, -100%) translateZ(${ this.calcZOffset( el.dataset.index ) }px) rotate(-5deg) scale(0.01)`;
                    break;
            }

            const iconEl = el.querySelector( '.icon' );
            if ( iconEl )
            {
                iconEl.style.display = 'none';
            }

            const personTitleEl = el.querySelector( '.person-title' );
            if ( personTitleEl )
            {
                personTitleEl.style.display = 'none';
            }
        },

        onEnter( el, done ) {
            done();
        },

        onLeave( el, done ) {
            // console.log( 'onLeave' );
            // done();

            anime( {
                targets: el,
                rotateX: '-30deg',
                opacity: 0,
                duration: 400,
                easing: 'easeInSine',
                delay: parseInt( el.dataset.index ) * 0.15 * STAGGER_DELAY,
                complete: done
            } );
        },

        onAfterLeave( el, done ) {
            el.style.display = 'none';
            done();
        },

        revealDelay( obj ) {
            return obj.Settings && obj.Settings.revealDelay ? obj.Settings.revealDelay : 0;
        },

        objectClickHandler( chapterObject, chapterObjectEl ) {
            this.$emit( 'objectClick', chapterObject, chapterObjectEl );
        },

        stageObjectVideoEnded( soData ) {
            // console.log( 'stageObjectVideoEnded', soData );
            this.$emit( 'stageObjectVideoEnded', soData );
        },

        pauseVideoElements() {
            // console.log( 'StageRoot::pauseVideoElements', this.objects );
            if ( this.objects )
            {
                this.objects.forEach( ( object ) => {
                    object.pauseVideo();
                } );
            }
        },

        resumeVideoElements() {
            // console.log( 'StageRoot::resumeVideoElements' );
            if ( this.objects )
            {
                this.objects.forEach( ( object ) => {
                    object.resumeVideo();
                } );
            }
        },

        collapseObjects() {
            console.log( 'StageRoot::collapseObjects' );
            if ( this.chapterObjects )
            {
                this.chapterObjects.filter((o) => !(o.Settings && o.Settings.doNotCollapseAtEnd && o.Settings.doNotCollapseAtEnd == true)).forEach( ( o ) => {
                    // o.collapse();
                    // console.log( o.Identifier, o.AnimationType, this.objId( o ) );

                    const el = document.getElementById( this.objId( o ) );
                    if (!el) return;

                    if (o.AnimationType && o.AnimationType == 'Flip' ) {

                        anime( {
                            targets: el,
                            rotateX: '-90deg',
                            opacity: 0,
                            duration: 4000,
                            easing: 'easeInOutSine',
                            delay: parseInt( el.dataset.index ) * 0.15 * STAGGER_DELAY,
                            // complete: () => console.log(this)
                        } );

                    } else {
                        
                        anime( {
                            targets: el,
                            opacity: 0,
                            duration: 4000,
                            easing: 'easeInOutSine',
                            delay: parseInt( el.dataset.index ) * 0.15 * STAGGER_DELAY,
                            // complete: () => console.log(this)
                        } );
                    }

                } );
            }
        },
    }
};
</script>

<template>
    <div id="stage" :class="classes">

        <div v-if="chapter" id="objectsEl" class="objects">
            <TransitionGroup name="stgobj" :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave"
                @after-leave="onAfterLeave" appear>
                <StageObject v-for=" obj, index in chapterObjects" ref="objects" :key="objKey( obj )" :data="obj"
                    :index="index" :data-index="index" :data-ident="obj.Identifier" :data-anm="obj.AnimationType"
                    :data-revdel="revealDelay( obj )" :id="objId( obj )" @object-click="objectClickHandler" @video-ended="stageObjectVideoEnded">
                </StageObject>
            </TransitionGroup>
        </div>

        <ChapterTitle v-if="chapter" ref="title" :title="titleProxy" :titleStyle="titleStyle" :period="periodProxy"
            :periodStyle="periodStyle" :periodInfo="periodInfo" />

        <!--<div id="title-wrapper" class="title-wrapper"><h1>{{ chapter ? chapter.Title : "" }}</h1></div>-->
    </div>
</template>