<script>
export default {
    name: 'EndModal',

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    // mounted() {
    //     console.log( this.data );
    // },
};
</script>

<template>
    <div class="end-modal">
        <div class="end-modal__wrapper">
            <div class="end-modal__left"></div>
            <div class="end-modal__content">
                <h2 v-if="data.Title">{{ data.Title }}</h2>
                <p v-html="data.Body"></p>
                
                <div v-if="data.ButtonLink && data.ButtonTekst" class="end-modal__content__button-wrapper">
                    <a :href="data.ButtonLink">{{ data.ButtonTekst }}</a>
                </div>
            </div>
            <div class="end-modal__right"></div>
        </div>
    </div>
</template>