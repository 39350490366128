<script>
export default {
    name: 'ColorPlate',
    
    props: {
        cls: {
            type: String,
            default: ''
        },
    },    
}
</script>

<template>
    <div :class=" `plate ${this.cls}` "></div>
</template>