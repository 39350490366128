import { createApp } from 'vue';
import { createPinia } from 'pinia';

import { detect } from 'detect-browser';
import { isCellular, isPhoneType } from './util/device.js';

import VueGtag from "vue-gtag";
// import Haven from '@chiiya/haven';

import App from './App.vue';
import router from './router';
import mixins from './mixins';

import { useAppStore } from '@/store';

const pinia = createPinia();
const app = createApp( App );

window.djs_audio_tracks = [];

app.use( pinia );
app.use( router );
app.mixin( mixins );

if ( process.env.VUE_APP_GA_ID )
{
    // console.log( 'ga-id:', process.env.VUE_APP_GA_ID );
    app.use( VueGtag, { config: { 
        id: process.env.VUE_APP_GA_ID,
        params: {
            anonymize_ip: true
        } 
    } } );
}

const store = useAppStore();

if ( process.env.VUE_APP_STRAPI_URL )
{
    store.setStrapiUrl( process.env.VUE_APP_STRAPI_URL );
    
    const browser = detect() || { name: 'unknown', version: 0, os: 'unknown' };
    const isApple = browser.name == 'safari' || browser.name == 'ios';

    const findVideoUrls = (obj, key, vidUrls) => {
        for (let prop in obj) {
            if ( ( isApple && prop === 'VideoHEVC' ) || ( !isApple && prop === 'VideoVP9')) {
                if ( key != 'IntroVideo' ) continue;
                // console.log( `${key}: ${ store.strapiUrl }${ obj[ prop ].url }` );
                vidUrls.push( `${ store.strapiUrl }${ obj[ prop ].url }` );
            } else if (typeof obj[prop] === 'object') {
                findVideoUrls( obj[ prop ], prop, vidUrls );
            }
        }
    }

    const findImageUrls = (obj, imgUrls) => {
        for (let prop in obj) {
            if ( prop === 'Image' || prop === 'Image2' ) {
                if ( !obj[ prop ] ) continue;
                imgUrls.push( `${ store.strapiUrl }${ obj[ prop ].url }` );
            } else if (typeof obj[prop] === 'object') {
                findImageUrls( obj[ prop ], imgUrls );
            }
        }
    }

    store.fetchContent((success) => {
        if (success) {

            if ( isCellular || isPhoneType ) return;

            setTimeout(() => {

                console.log('Preloading assets');

                // preload 
                const vidUrls = [], imgUrls = [];
                findImageUrls( store.app_content, imgUrls );
                imgUrls.forEach( ( url, index ) => {
                    if ( !url || url == "" ) return;
                    let img = new Image();
                    img.onload = () => img = null;
                    setTimeout(() => img.src = url, 200 + index * 50);
                } );
                
                findVideoUrls(store.app_content, "app_content", vidUrls);
                vidUrls.forEach( ( url, index ) => {
                    if ( !url || url == "" ) return;
                    const v = document.createElement( "video" );
                    v.muted = true;
                    v.autoplay = false;
                    v.preload = "auto";
                    v.style.display = "none";
                    const s = document.createElement( "source" );
                    s.src = url;
                    s.type = isApple ? "video/mp4" : "video/webm";
                    v.appendChild( s );
                    setTimeout(() => document.body.appendChild( v ), 5000 + index * 1000);
                });

                // preload object elements 
                // const imgUrls = [];
                // findImageUrls( store.app_content, imgUrls );
                // imgUrls.map( ( url, index ) => {
                //     let image = new Image();
                //     image.onload = (e) => image = null;
                //     setTimeout(() => image.src = url, 100 + index * 50);
                // } );
                
            }, 4000);

        }
    });

} else {
    console.info( process.env );
    throw new Error( 'No Strapi URL found' );
}

// Start chapter
let urlParams = new URLSearchParams( window.location.search );
window.START_CHAPTER = 1;
if ( urlParams.has( 'startat' ) )
{
    window.START_CHAPTER = urlParams.get( 'startat' );
}
if ( urlParams.has( 'object' ) )
{
    window.START_OBJECT = urlParams.get( 'object' );
}

// Debug
if ( urlParams.has( 'debug' ) ) {
    document.body.classList.add( 'is-debug' );
    window.DJS_IS_DEBUG = true;
} else {
    window.DJS_IS_DEBUG = false;
}
// Skip intro
if ( urlParams.has( 'skipintro' ) ) {
    window.DJS_SKIP_INTRO = true;
} else {
    window.DJS_SKIP_INTRO = false;
}

// Disable media buttons 
if ( navigator && navigator.mediaSession ) {

    const handleMediaEvents = (  ) => {};

    navigator.mediaSession.setActionHandler( 'play', handleMediaEvents );
    navigator.mediaSession.setActionHandler( 'pause', handleMediaEvents );
    navigator.mediaSession.setActionHandler( 'seekbackward', handleMediaEvents );
    navigator.mediaSession.setActionHandler( 'seekforward', handleMediaEvents );
    navigator.mediaSession.setActionHandler( 'previoustrack', handleMediaEvents );
    navigator.mediaSession.setActionHandler( 'nexttrack', handleMediaEvents );
} else {
    console.log(`navigator.mediaSession is not available`);
}

console.log( `%cDe Joodse Straat`, 'font-size: 1.1rem; color: green' );
console.log( `%c${ require( '../package.json' ).version } %c${ process.env.NODE_ENV }`, 'font-size: 1rem; color: orange', 'font-size: 1rem; color: magenta' );

app.mount( '#app' );

// YouTube API 
var tag = document.createElement( 'script' );
tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName( 'script' )[ 0 ];
firstScriptTag.parentNode.insertBefore( tag, firstScriptTag );