<script>
import { useAppStore } from '@/store';
import { ref } from 'vue';

import DetailObject from '@/components/DetailObject.vue';

import ContentWig from '@/templates/ContentWig.vue';
import ContentRight from '@/templates/ContentRight.vue';
import ContentBottomLeft from '@/templates/ContentBottomLeft.vue';
import ContentLeft from '@/templates/ContentLeft.vue';
import ContentLens from '@/templates/ContentLens.vue';
import CompareRight from '@/templates/CompareRight.vue';
import PersonHorizontal from '@/templates/PersonHorizontal.vue';
import ExtraPerson from '@/templates/ExtraPerson.vue';
import DetailExitButton from '@/components/DetailExitButton.vue';

export default {
    name: 'DetailView',

    props: {
        selectedObject: {
            type: Object,
            required: true,
        },
    },

    emits: [
        "linkClick",
        "exitDetail"
    ],

    components: {
        DetailExitButton,
        DetailObject,
        CompareRight,
        ContentWig,
        ContentRight,
        ContentLeft,
        ContentBottomLeft,
        ContentLens,
        ExtraPerson,
        PersonHorizontal,
    },

    setup() {
        const store = useAppStore();

        const recordPlayer = ref( null );

        const contentLens = ref( null );

        const compareRight = ref( null );

        const detailObjects = ref( null );

        return {
            detailObjects,
            recordPlayer,
            contentLens,
            compareRight,
            store,
        };
    },

    computed: {
        safeDetailObjects() {
            return this.detailObjects.map((obj) => obj.Type && obj.Xpos && obj.Ypos) || [];
        },

        styleValue() {
            if ( [ 'ContentLeft', 'ContentBottomLeft', 'ContentRight', 'ContentWig' ].includes( this.template ) )
            {
                return this.selectedObject.Detail && this.selectedObject.Detail.Image
                    ? `background-image: url(${ this.store.strapiUrl }${ this.selectedObject.Detail.Image.url });`
                    : 'background-image: none;';
            }
            return `background-image: none;`;
        },

        // exitButtonStyle() {
        //     const styles = [];
        //     if ( ![ 'PersonHorizontal', 'ExtraPerson' ].includes(this.template) ) {
        //         if (this.selectedObject.Detail && this.selectedObject.Detail.BackPosX && this.selectedObject.Detail.BackPosX != "") {
        //             styles.push( ` left: ${ this.selectedObject.Detail.BackPosX / 19.20 }vw; ` );
        //         }
        //         if (this.selectedObject.Detail && this.selectedObject.Detail.BackPosY && this.selectedObject.Detail.BackPosY != "") {
        //             styles.push( ` top: ${ this.selectedObject.Detail.BackPosY / 19.20 }vw; ` );
        //         }
        //     }
        //     return styles.join( '' );
        // },

        ariaLabel() {
            if ( this.selectedObject.Detail && this.selectedObject.Detail.Image && this.selectedObject.Detail.Image.alternativeText && this.selectedObject.Detail.Image.alternativeText != "" )
            {
                return this.selectedObject.Detail.Image.alternativeText;
            }
            return "";
        },

        hasPhotoCaption() {
            if ( this.selectedObject.Detail && this.selectedObject.Detail.Settings && this.selectedObject.Detail.Settings.hideCaption && this.selectedObject.Detail.Settings.hideCaption == true ) {
                return false;
            }
            return this.selectedObject.Detail && this.selectedObject.Detail.Image && this.selectedObject.Detail.Image.caption && this.selectedObject.Detail.Image.caption != "";
        },

        photoCaption() {
            return this.selectedObject.Detail.Image && this.selectedObject.Detail.Image.caption ? this.selectedObject.Detail.Image.caption : "";
        },

        photoCaptionStyle() {
            return this.hasPhotoCaption && this.selectedObject.Detail.Settings && this.selectedObject.Detail.Settings.fotoCaptionTextColor && this.selectedObject.Detail.Settings.fotoCaptionTextColor != ""
                ? `color: ${ this.selectedObject.Detail.Settings.fotoCaptionTextColor };`
                : "";
        },

        extraDetailClasses() {
            if ( this.selectedObject.Detail && this.selectedObject.Detail.Settings && this.selectedObject.Detail.Settings.extraClasses )
            {
                return ` ${this.selectedObject.Detail.Settings.extraClasses} `;
            }
            return '';
        },

        title() {
            return this.selectedObject.Detail && this.selectedObject.Detail.Title
                ? this.selectedObject.Detail.Title
                : this.selectedObject.Title;
        },

        template() {
            return this.selectedObject.Detail && this.selectedObject.Detail.Template
                ? this.selectedObject.Detail.Template
                : 'ContentLeft';
        },

        // isPersonTemplate() {
        //     // return [ 'PersonVertical', 'PersonHorizontal' ].includes( this.template );
        //     return [ 'ExtraPerson', 'PersonHorizontal' ].includes( this.template );
        // },

        // hasLayer() {
        //     return this.selectedObject.Layers && this.selectedObject.Layers.length > 0;
        // },

        // isGraphicalExit() {
        //     return ![ 'CompareRight', 'Lens' ].includes( this.template ) && !this.isPersonTemplate && this.hasLayer;
        // },

        // graphicLayer() {
        //     if (!this.hasLayer ) return "";
        //     return `${this.store.strapiUrl}${this.selectedObject.Layers[0].Image.url}`;
        // },

        // graphicLayerStyle() {
        //     const styles = [];
        //     // return `background-image: url(${this.graphicLayer});`;
        //     // `background-image: url(${ graphicLayer })`;
        //     const layerIndex = this.selectedObject.Detail.Settings && this.selectedObject.Detail.Settings.backButtonLayerIndex ? this.selectedObject.Detail.Settings.backButtonLayerIndex : 0;
        //     // console.log( "graphicLayerStyle", layerIndex, this.selectedObject.Detail.Settings)
        //     if ( this.hasLayer ) {
        //         styles.push( `background-image: url(${ this.store.strapiUrl }${ this.selectedObject.Layers[ layerIndex ].Image.url });` );
        //         // styles.push(` width: ${ this.selectedObject.Layers[ 0 ].Image.width / 19.20 }vw; `);
        //         // styles.push(` height: ${ this.selectedObject.Layers[ 0 ].Image.height / 19.20 }vw; `);
        //         styles.push(` width: ${ this.selectedObject.Layers[ layerIndex ].Image.width  }px; `);
        //         styles.push(` height: ${ this.selectedObject.Layers[ layerIndex ].Image.height }px; `);
        //     }
        //     return styles.join( '' );
        // },
    },

    methods: {
        exit() {
            console.log( 'Detail::exit' );

            if ( this.detailObjects )
            {
                for ( let detailObject of this.detailObjects ) {
                    if( detailObject.exit ) detailObject.exit();
                }
            }

            // if ( this.recordPlayer )
            // {
            //     this.recordPlayer.exit();
            // }

            if ( this.compareRight )
            {
                this.compareRight.exit();
            }

            if ( this.contentLens )
            {
                this.contentLens.exit();
            }
        },

        postEnter() {
            console.log( 'Detail::postEnter', this.template || "No template" );
            // if ( this.template && this.template == 'PersonHorizontal' )
            if ( this.template && [ 'PersonHorizontal', 'ExtraPerson' ].includes(this.template) )
            {
                const shapesContainer = document.getElementById( 'shapesContainer' );
                if ( shapesContainer )
                {
                    shapesContainer.classList.add( 'bleed' );
                }
            }
            if ( this.contentLens )
            {
                this.contentLens.enter();
            }
            if ( this.compareRight )
            {
                this.compareRight.enter();
            }

            if ( this.detailObjects )
            {
                for ( let detailObject of this.detailObjects )
                {
                    detailObject.postEnter();
                }
            }
        },

        postExit() {
            console.log( 'Detail::postExit', this.template || "No template" );
            const shapesContainer = document.getElementById( 'shapesContainer' );
            if ( shapesContainer )
            {
                shapesContainer.classList.remove( 'bleed' );
            }
        },

        detailObject_onPlayAudio( dataId ) {

            if ( this.detailObjects )
            {
                for ( let detailObject of this.detailObjects )
                {
                    // console.log( dataId , `do-${ detailObject.data.id }` )
                    if (dataId == `do-${ detailObject.data.id }` ) continue;
                    if ( detailObject.stopPlay ) detailObject.stopPlay(true);
                }
            }
        },

        pause() {
            console.log('Detail::pause');

            if ( this.detailObjects )
            {
                for ( let detailObject of this.detailObjects )
                {
                    if ( detailObject.pause ) detailObject.pause();
                }
            }
        },

        resume() {
            console.log('Detail::resume');

            if ( this.detailObjects )
            {
                for ( let detailObject of this.detailObjects )
                {
                    if ( detailObject.resume ) detailObject.resume();
                }
            }
        },

        linkHandler(linkTargetId) {
            this.$emit( 'linkClick', linkTargetId );
        }
    },

    mounted() {
        // console.log(document.querySelectorAll('.ascale'));
        // console.log(this.selectedObject)
    },

    watch: {
        selectedObject() {
            if ( this.selectedObject.Title )
            {

                console.log( "Detail started", this.selectedObject.Title, `--${ this.template }--`, );

                // // RecordPlayer: Check for audio
                // if ( this.template == 'RecordPlayer' && this.selectedObject.Detail && this.selectedObject.Detail.Settings && this.selectedObject.Detail.Settings.audioFileId )
                // {
                //     const audioFile = this.store.getAudioFileById( this.selectedObject.Detail.Settings.audioFileId );
                //     if ( !audioFile ) return;

                //     window.djs_current_audio = new Audio();
                //     window.djs_current_audio.src = `${ this.store.strapiUrl }${ audioFile.MP3.url }`;
                //     console.log( "RecordPlayer audioFile", `${ this.store.strapiUrl }${ audioFile.MP3.url }` );

                //     return;
                // }
            }
        },
    },
};
</script>

<template>
    <div class="detail-cover"></div>

    <div v-if="selectedObject && selectedObject.Detail" 
        :class="`detail template-${template} ${extraDetailClasses}`" 
        :aria-label="ariaLabel"
        :style="styleValue"
        >

        <div v-if="hasPhotoCaption" class="foto-caption" :style="photoCaptionStyle">{{ photoCaption }}</div>

        <PersonHorizontal v-if="template == 'PersonHorizontal'" :selectedObject="selectedObject"></PersonHorizontal>

        <ExtraPerson v-if="template == 'ExtraPerson'" :selectedObject="selectedObject"></ExtraPerson>

        <!--<PersonVertical v-if="template == 'PersonVertical'" :selectedObject="selectedObject"></PersonVertical>-->

        <ContentWig v-if="template == 'ContentWig'" :selectedObject="selectedObject"></ContentWig>

        <ContentRight v-else-if="template == 'ContentRight'" :selectedObject="selectedObject">
        </ContentRight>

        <ContentLeft v-else-if="template == 'ContentLeft'" :selectedObject="selectedObject">
        </ContentLeft>

        <ContentBottomLeft v-else-if="template == 'ContentBottomLeft'" :selectedObject="selectedObject">
        </ContentBottomLeft>

        <ContentLens v-else-if="template == 'Lens'" :selectedObject="selectedObject" ref="contentLens">
        </ContentLens>

        <!--<RecordPlayer v-else-if="template == 'RecordPlayer'" :selectedObject="selectedObject" ref="recordPlayer">
        </RecordPlayer>-->

        <CompareRight v-else-if="template == 'CompareRight'" :selectedObject="selectedObject" ref="compareRight">
        </CompareRight>

        <div class="detail-objects" v-if="Array.isArray( selectedObject.DetailObjects ) && selectedObject.DetailObjects.length">
            <DetailObject v-for="obj, index in selectedObject.DetailObjects" 
                :data="obj"
                :index="index" 
                :key="`detail-obj-${obj.id}`" 
                :caption="photoCaption"
                ref="detailObjects" 
                @play-audio="detailObject_onPlayAudio" 
                @link-click="linkHandler"
                >
            </DetailObject>
        </div>

        <DetailExitButton :selectedObject="selectedObject" :template="template" @click="$emit( 'exitDetail' )">
        </DetailExitButton>

</div></template>