<script>
import anime from 'animejs/lib/anime.es.js';

const STAGGER_DELAY = 160;
const PRE_DELAY = 100;

let _wedgeIndex = 1;

export default {
    name: 'WedgeObject',

    props: {
        data: {
            type: Object,
        },
    },

    computed: {
        wedgeIndex() {
            return _wedgeIndex++;
        },
    },

    methods: {

        isWedge( asset ) {
            if ( !this.data ) return false;
            return this.data.asset === asset;
        },

        wedgeStyle() {
            const pos = this.data.offsetPercentage || { x: 0, y: 0 };
            return `
                transform: translate(${ pos.x }%, ${ pos.y }%) scale(${ this.data.scale || 1 }) rotate(${ this.data.rotation || 0 }deg);
            `;
        },

        onBeforeEnter( el ) {
            // console.log( 'onBeforeEnter', el );
            // el.style.display = 'none';
            el.style.opacity = 0;
        },

        onEnter( el, done ) {
            // console.log( 'onEnter' );
            // el.style.display = 'block';
            anime( {
                targets: el,
                opacity: 1,
                duration: 1000,
                easing: 'easeInSine',
                delay: PRE_DELAY + parseInt( el.dataset.index ) * STAGGER_DELAY,
                complete: done
            } );
        },

        onLeave( el, done ) {
            // console.log( 'onLeave', el );
            anime( {
                targets: el,
                opacity: 0,
                duration: 1400,
                easing: 'linear',
                delay: parseInt( el.dataset.index ) * STAGGER_DELAY,
                complete: done
            } );
        },
    }
};
</script>

<template>
    <TransitionGroup name="wedgeobj" :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave"
        appear>

        <img v-if="isWedge( 'wedge-blue-cloud' )" :data-index="wedgeIndex" class="wedge" :style="wedgeStyle()" src="@/assets/wigwolk.webp">

        <svg v-if="isWedge( 'wedge-blue-1' )" :data-index="wedgeIndex" class="wedge" :style="wedgeStyle()"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="855" height="1577"
            viewBox="0 0 855 1577">
            <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#5fc7ee" />
                    <stop offset="0.576" stop-color="#5cc1e7" />
                    <stop offset="1" stop-color="#175177" stop-opacity="0.988" />
                </linearGradient>
            </defs>
            <path id="Polygon_4" data-name="Polygon 4" d="M427.5,0,855,1577H0Z" transform="translate(855 1577) rotate(180)"
                fill="url(#linear-gradient)" style="mix-blend-mode: multiply;isolation: isolate" />
        </svg>

        <svg v-if="isWedge( 'wedge-blue-2' )" :data-index="wedgeIndex" class="wedge" :style="wedgeStyle()"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="855" height="1577"
            viewBox="0 0 855 1577">
            <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#5fc7ee" />
                    <stop offset="0.576" stop-color="#5cc1e7" />
                    <stop offset="1" stop-color="#175177" stop-opacity="0.988" />
                </linearGradient>
            </defs>
            <path id="Polygon_3" data-name="Polygon 3" d="M427.5,0,855,1577H0Z" transform="translate(855 1577) rotate(180)"
                fill="url(#linear-gradient)" style="mix-blend-mode: multiply;isolation: isolate" />
        </svg>

        <svg v-if="isWedge( 'wedge-blue-3' )" :data-index="wedgeIndex" class="wedge" :style="wedgeStyle()"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="942.832" height="1258.152"
            viewBox="0 0 942.832 1258.152">
            <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#5fc7ee" />
                    <stop offset="0.576" stop-color="#5cc1e7" />
                    <stop offset="1" stop-color="#175177" stop-opacity="0.988" />
                </linearGradient>
            </defs>
            <path id="Path_2" data-name="Path 2" d="M297.41,5.9,616.591,1128.3l-594.82-11.8Z"
                transform="translate(577.387 1271.143) rotate(-160)" fill="url(#linear-gradient)"
                style="mix-blend-mode: multiply;isolation: isolate" />
        </svg>

        <svg v-if="isWedge( 'wedge-blue-4' )" :data-index="wedgeIndex" class="wedge" :style="wedgeStyle()"
            xmlns="http://www.w3.org/2000/svg" width="2244.937" height="2214.188" viewBox="0 0 2244.937 2214.188">
            <path id="Polygon_9" data-name="Polygon 9" d="M633,0l633,1889H0Z"
                transform="translate(2244.937 1288.295) rotate(133)" fill="#010811" opacity="0.925"
                style="mix-blend-mode: multiply;isolation: isolate" />
        </svg>

        <svg v-if="isWedge( 'wedge-blue-5' )" :data-index="wedgeIndex" class="wedge" :style="wedgeStyle()"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="855" height="1577"
            viewBox="0 0 855 1577">
            <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#5fc7ee" />
                    <stop offset="0.576" stop-color="#5cc1e7" />
                    <stop offset="1" stop-color="#175177" stop-opacity="0.988" />
                </linearGradient>
            </defs>
            <path id="Path_33" data-name="Path 33" d="M427.5,0,855,1577H0Z" transform="translate(855 1577) rotate(180)"
                fill="url(#linear-gradient)" style="mix-blend-mode: multiply;isolation: isolate" />
        </svg>

        <svg v-if="isWedge( 'wedge-orange-1' )" :data-index="wedgeIndex" class="wedge" :style="wedgeStyle()"
            xmlns="http://www.w3.org/2000/svg" width="2058.081" height="2271.227" viewBox="0 0 2058.081 2271.227">
            <path id="Polygon_1" data-name="Polygon 1" d="M633,0l633,1889H0Z"
                transform="translate(2058.081 1619.189) rotate(149)" fill="#f36b3a"
                style="mix-blend-mode: multiply;isolation: isolate" />
        </svg>

        <svg v-if="isWedge( 'wedge-yellow-1' )" :data-index="wedgeIndex" class="wedge" :style="wedgeStyle()"
            xmlns="http://www.w3.org/2000/svg" width="2005.84" height="1652.191" viewBox="0 0 2005.84 1652.191">
            <path id="Polygon_5" data-name="Polygon 5" d="M542.5,0,1085,1725H0Z"
                transform="translate(406.448 1652.191) rotate(-112)" fill="#fbd301"
                style="mix-blend-mode: multiply;isolation: isolate" />
        </svg>

        <svg v-if="isWedge( 'wedge-yellow-2' )" :data-index="wedgeIndex" class="wedge" :style="wedgeStyle()"
            xmlns="http://www.w3.org/2000/svg" width="855" height="1577" viewBox="0 0 855 1577">
            <path id="Polygon_4" data-name="Polygon 4" d="M427.5,0,855,1577H0Z" transform="translate(855 1577) rotate(180)"
                fill="#fad230" opacity="0.538" style="mix-blend-mode: multiply;isolation: isolate" />
        </svg>

        <svg v-if="isWedge( 'wedge-yellow-3' )" :data-index="wedgeIndex" class="wedge" :style="wedgeStyle()"
            xmlns="http://www.w3.org/2000/svg" width="573.761" height="1116.427" viewBox="0 0 573.761 1116.427">
            <path id="Polygon_3" data-name="Polygon 3" d="M197.5,0,395,1064H0Z"
                transform="matrix(-0.985, 0.174, -0.174, -0.985, 573.761, 1047.835)" fill="#fbd330"
                style="mix-blend-mode: multiply;isolation: isolate" />
        </svg>

        <svg v-if="isWedge( 'wedge-orange-3' )" :data-index="wedgeIndex" class="wedge" :style="wedgeStyle()"
            xmlns="http://www.w3.org/2000/svg" width="640.672" height="1127.954" viewBox="0 0 640.672 1127.954">
            <path id="Polygon_5" data-name="Polygon 5" d="M197.5,0,395,1064H0Z"
                transform="translate(383.267 1127.954) rotate(-166)" fill="#e83e1a"
                style="mix-blend-mode: multiply;isolation: isolate" />
        </svg>

</TransitionGroup></template>