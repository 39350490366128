export default class VideoPlayerProxy {

    el = null;
    isPlaying = false;

    constructor(el) {
        this.el = el;
    }

    getIsPlaying() {
        return this.isPlaying;
    }
}