<script>
import { useAppStore } from '@/store';

export default {
    name: 'ExtraPerson',

    props: {
        selectedObject: {
            type: Object,
            required: true,
        },
    },

    computed: {
        title() {
            return this.selectedObject.Detail && this.selectedObject.Detail.Title
                ? this.selectedObject.Detail.Title
                : (this.selectedObject.Title || '');
        },
    },
    
    setup() {
        const store = useAppStore();

        return {
            store
        };
    },

    mounted() {
        console.log( "ExtraPerson|mounted" );
    },
};

</script>
<template>
    <div class="detail-content">
        <div id="shapesContainer" class="shapes">
            <img class="tmpl-content-ellipse" src="@/assets/ellipse_blue.svg">
        </div>

        <!--<img class="detail-image" :src="store.strapiUrl + selectedObject.Detail.Image.url" alt="detail image">-->
        <main>
            <div class="content-block">
                <h2>{{ title }}</h2>
                <article v-html="selectedObject.Detail.BodyContent"></article>
            </div>
        </main>

    </div>  
</template>